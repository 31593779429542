import {
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import ResetPasswordRequestForm from '../components/ResetPasswordRequestForm'
import ResetPasswordForm from '../components/ResetPasswordForm'
import { useParams, useLocation } from 'react-router-dom'

const queryClient = new QueryClient()

const ResetPasswordRequest = () => {

    const { token } = useParams();

    return(
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <ResetPasswordRequestForm />
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordRequest