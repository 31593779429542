
import { weekdays } from '../utils/datetime.js'; 

const LocationEditAction = {
    Setup: 'setup',
    RevertEdit: 'revertEdit',
    BasicInformation: 'basicInformation',
    BasicFieldEdit: 'basicFieldEdit',

    HoursAddPeriod: 'hoursAddPeriod',
    HoursEditPeriod: 'hoursEditPeriod',
    HoursRemovePeriod: 'hoursRemovePeriod',
    HoursToggleClosed: 'hoursToggleClosed',

    SpecialHoursToggle: 'specialHoursToggle',
    SpecialHoursRemove: 'specialHoursRemove',
    SpecialHoursEditPeriod: 'specialHoursEditPeriod'
}

const LocationEditsReducer = (state, action) => {

    // Helper functions
    const timeObjToStr = (obj) => {
        let hours = obj.hours > 12 ? obj.hours - 12 : obj.hours;
        let minutes = obj.minutes === undefined ? '00' : (obj.minutes < 10 ? `0${obj.minutes}` : obj.minutes);
        let phase = obj.hours > 12 ? 'PM' : 'AM';
        return `${hours}:${minutes} ${phase}`;
    }

    const objDeepCopy = obj => JSON.parse(JSON.stringify(obj));

    const objEquality = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

    let newState = { ...state };

    switch (action.type) {
        // Setup the initial state of the reducer
        case LocationEditAction.Setup:
            // Set the editable data

            // State has already been set up
            if (Object.keys(state).length !== 0) {
                return newState;
            }

            // We'll only copy over what we know we need, in a form that is convenient to us.
            const data = { };
            data.basicInformation = { };
            data.contact = { };
            data.hours = { };
            data.location = { };

            // Popualte the basic information
            data.basicInformation.title = action.data.title;

            if (action.data.profile) {
                data.basicInformation.description = action.data.profile.description;
            } else {
                data.basicInformation.description = '';
            }

            // Populate the contact fields
            data.contact.website = action.data.websiteUri;
            data.contact.primaryPhone = action.data.phoneNumbers[0].primaryPhone;

            // Google guarantees only 2 additional phone numbers and requires them on update, so we'll just have them static
            if (action.data.phoneNumbers[0].additionalPhones === undefined) {
                data.contact.additionalPhone1 = '';
                data.contact.additionalPhone2 = '';
            } else {
                let additional = action.data.phoneNumbers[0].additionalPhones.padTo(2, '');
                data.contact.additionalPhone1 = additional[0];
                data.contact.additionalPhone2 = additional[1];
            }

            // Popualte the location fields
            data.location.address = action.data.storefrontAddress.addressLines.join(' ');
            data.location.city = action.data.storefrontAddress.locality;
            data.location.state = action.data.storefrontAddress.administrativeArea;
            data.location.zip = action.data.storefrontAddress.postalCode;

            // Do some massaging to the data to make modifying the hours a lot easier
            let hours = action.data.regularHours.periods.reduce((total, cur) => {
                if (total[cur.openDay] === undefined) {
                    total[cur.openDay] = { periods: [ ], isClosed: false };
                }
               total[cur.openDay].periods.push([ timeObjToStr(cur.openTime), timeObjToStr(cur.closeTime) ])
                return total;
            }, { });

            // Add day objects for days that are initially marked as closed
            weekdays.forEach(day => {
                if (hours[day] === undefined) {
                    hours[day] = { periods: [ ], isClosed: true };
                }
            });

            data.hours.regular = hours;

            // Now the special hours
            let specialHours = action.data.specialHours.specialHourPeriods.reduce((total, cur) => {
                let startDate = `${cur.startDate.year}-${cur.startDate.month}-${cur.startDate.day}`;
                let endDate = `${cur.endDate.year}-${cur.endDate.month}-${cur.endDate.day}`;
                if (startDate === endDate) {
                    if (!total[startDate]) {
                        total[startDate] = {
                                open: '9:00 AM',
                                close: '5:00 PM',
                                allDay: true,
                                isClosed: true
                            }
                    }
                }
                return total;
            }, { });

            data.hours.special = specialHours;

            // Set the state
            newState.original = objDeepCopy(data);
            newState.editable = objDeepCopy(data);
            newState.edits = {
                basicInformation: { },
                contact: { },
                location: { },
                hours: {
                    regular: { },
                    special: { }
                }
            };

            return newState;

            

        case LocationEditAction.RevertEdit:
            // Check that the edit exists
            if (action.category === 'hours') {
                if (newState.edits[action.category][action.subcategory][action.key] === undefined) {
                    return newState;
                }
            } else {
                if (newState.edits[action.category][action.key] === undefined) {
                    return newState;
                }
            }

            // Replace the change with the original
            if (action.category === 'hours') {
                newState.editable[action.category][action.subcategory][action.key] = objDeepCopy(newState.original[action.category][action.subcategory][action.key]);
                delete newState.edits[action.category][action.subcategory][action.key];
            } else {
                newState.editable[action.category][action.key] = objDeepCopy(newState.original[action.category][action.key]);
                delete newState.edits[action.category][action.key];
            }

            // Remove the edit entirely


            return newState;


        
        case LocationEditAction.BasicFieldEdit:
            newState.editable[action.category][action.field] = action.value;
            let originalBasicField = newState.original[action.category][action.field];

            if (originalBasicField === action.value) {
                delete newState.edits[action.category][action.field];
                return newState;
            }

            newState.edits[action.category][action.field] = {
                from: originalBasicField,
                to: action.value
            };

            return newState;



        // Handle the basic information edits (title, description)
        case LocationEditAction.BasicInformation:
            // Make the change to the basic info field

            newState.editable.basicInformation[action.field] = action.value;
            let originalValue = newState.original.basicInformation[action.field];

            // If the value is the same as the original, remove the edit
            if (originalValue === action.value) {
                delete newState.edits.basicInformation[action.field];
                return newState;
            }

            // Otherwise update the edits
            newState.edits.basicInformation[action.field] = {
                from: originalValue,
                to: action.value,
                display: action.display
            }
            return newState;



        // Add hours period to a day
        case LocationEditAction.HoursAddPeriod:

            // To keep this function pure, we'll perform an index check here.
            if (action.newIdx !== newState.editable.hours.regular[action.day].periods.length) {
                return newState;
            }

            // Create a new period and add it to the editable version
            let newPeriod = [ "9:00 AM", "5:00 PM" ];

            // Add the new period to the editable state
            newState.editable.hours.regular[action.day].periods.push(newPeriod);

            if (newState.edits.hours.regular[action.day] === undefined) {
                // Create the edit for this day if it does not exist.
                newState.edits.hours.regular[action.day] = {
                    from: newState.original.hours.regular[action.day],
                    to: newState.editable.hours.regular[action.day]
                };
            } else {
                // Otherwise update the existing edit
                newState.edits.hours.regular[action.day].to.periods.push(newPeriod);
            }

            return newState;
        

        
        // Edit the hours of an existing period
        case LocationEditAction.HoursEditPeriod:
            // Check that the index has the values we expect it to have
            let periodToEdit = newState.editable.hours.regular[action.day].periods[action.idx];
            if (periodToEdit[0] !== action.currentValues[0] || periodToEdit[1] !== action.currentValues[1]) {
                return newState;
            }

            // Do not allow the user to select either field as blank
            if (action.start === undefined || action.end === undefined) {
                return newState;
            }

            // Edit the period
            newState.editable.hours.regular[action.day].periods[action.idx] = [ action.start, action.end ];

            // Check if there is an edit for this day
            if (newState.edits.hours.regular[action.day] !== undefined) {
                // Update the existing edit
                newState.edits.hours.regular[action.day].to.periods[action.idx] = [ action.start, action.end ];
            } else {
                // Create an edit for this location
                newState.edits.hours.regular[action.day] = {
                    from: newState.original.hours.regular[action.day],
                    to: newState.editable.hours.regular[action.day]
                }
            }

            // Similarity check for original and edit
            if (objEquality(newState.edits.hours.regular[action.day].to, newState.edits.hours.regular[action.day].from)) {
                delete newState.edits.hours[action.day];
            }

            return newState;



        // Remove an hours period from a day
        case LocationEditAction.HoursRemovePeriod:

            // Check that the index has the values we expect it to have            
            let toRemove = newState.editable.hours.regular[action.day].periods[action.idx];

            if (toRemove === undefined || toRemove[0] !== action.start || toRemove[1] !== action.end) {
                return newState;
            }

            // Remove from the editable state 
            newState.editable.hours.regular[action.day].periods.splice(action.idx, 1);

            // Remove from the list of edits
            newState.edits.hours.regular[action.day].to.periods.splice(action.idx, 1);

            // Check that the edit is still different from the original
            if (objEquality(newState.edits.hours.regular[action.day].to, newState.edits.hours.regular[action.day].from)) {
                delete newState.edits.hours.regular[action.day];
            }
            
            return newState;
            


        // Mark a day as closed/open
        case LocationEditAction.HoursToggleClosed:

            const day = newState.editable.hours.regular[action.day];

            // Don't make any updates if the passed value already matches the state of the closing.
            if (day.isClosed === action.value) {
                return newState;
            }

            // Update the closed state
            day.isClosed = action.value

            // Check if there is an edit for this day
            if (newState.edits.hours.regular[action.day] !== undefined) {
                // Update the existing edit
                newState.edits.hours.regular[action.day].to.isClosed = action.value;
            } else {
                // Create an edit for this location
                newState.edits.hours.regular[action.day] = {
                    from: newState.original.hours.regular[action.day],
                    to: newState.editable.hours.regular[action.day]
                }
            }

            // Remove the edit if it is now the same as the initial
            if (objEquality(newState.edits.hours.regular[action.day].to, newState.edits.hours.regular[action.day].from)) {
                delete newState.edits.hours.regular[action.day];
            }

            return newState;
        

        
        case LocationEditAction.SpecialHoursToggle:
            // Check that the date is valid
            if (newState.editable.hours.special[action.date] === undefined) {
                return newState;
            }

            // Toggle the state value
            newState.editable.hours.special[action.date][action.field] = action.value;

            // Check if there is an edit for this special hours date
            if (newState.edits.hours.special[action.date] !== undefined) {
                // Update the existing edit
                newState.edits.hours.special[action.date].to[action.field] = action.value;
            } else {
                // Create an edit for this location
                newState.edits.hours.special[action.date] = {
                    from: newState.original.hours.special[action.date],
                    to: newState.editable.hours.special[action.date]
                }
            }

            // Check if the new change is the same as the original
            if (objEquality(newState.edits.hours.special[action.date].to, newState.edits.hours.special[action.date].from)) {
                delete newState.edits.hours.special[action.date];
            }
            
            return newState;


        
        case LocationEditAction.SpecialHoursAdd:

            // Check if there are already hours with that date
            if (newState.editable.hours.special[action.date] !== undefined) {
                return newState;
            }

            // Create the new special hours object
            let newSpecialHour = {
                open: '9:00 AM',
                close: '5:00 PM',
                allDay: true,
                isClosed: true
            };

            newState.editable.hours.special[action.date] = newSpecialHour;

            // Add the edit for the new date
            newState.edits.hours.special[action.date] = {
                from: 'None',
                to: newSpecialHour
            };

            return newState;



        case LocationEditAction.SpecialHoursEditPeriod:

            // Check that the date exists
            if (newState.editable.hours.special[action.date] === undefined) {
                return newState;
            }

            // Modify the period
            newState.editable.hours.special[action.date][action.field] = action.value;

            // Check if there is an edit
            if (newState.edits.hours.special[action.date] !== undefined) {
                // Update the existing edit
                newState.edits.hours.special[action.date].to[action.field] = action.value;
            } else {
                // Create an edit for this location
                newState.edits.hours.special[action.date] = {
                    from: newState.original.hours.special[action.date],
                    to: newState.editable.hours.special[action.date]
                }
            }

            // Remove the edit if it is now the same as the initial
            if (objEquality(newState.edits.hours.special[action.date].to, newState.edits.hours.special[action.date].from)) {
                delete newState.edits.hours.special[action.date];
            }

            return newState;


        case LocationEditAction.SpecialHoursRemove:

            // Check if the date exists
            if (newState.editable.hours.special[action.date] === undefined) {
                return newState;
            }

            // Mark as removed
            newState.editable.hours.special[action.date].markedAsRemoved = true;

            // Did the date not exist in the original data?
            if (newState.original.hours.special[action.date] === undefined) {
                // Remove the date from the edits
                delete newState.edits.hours.special[action.date];
            } else {
                // Create an edit for this location
                newState.edits.hours.special[action.date] = {
                    from: newState.original.hours.special[action.date],
                    to: 'None'
                }
            }


            return newState;

        // Unrecognized action type
        default:
            console.error('Unknown action type', action.type);

    }

    throw Error(`Unknown action type ${action.type} in action ${action}`);

}

export { LocationEditsReducer, LocationEditAction }
