
import { Tabs, Tab } from '@nextui-org/react';

import MailgunAddNewKey from './MailgunAddNewKey';
import MailgunKeyList from './MailgunKeyList';

import { useQuery } from '@tanstack/react-query';
import { getUser } from '../../endpoints/auth';
import MailgunRoutesList from './MailgunRoutesList';
import { getUserKeys } from '../../endpoints/mailgun';

const MailgunHome = () => {

    const { data: user } = useQuery({ queryKey: [ 'user' ], queryFn: getUser, placeholderData: undefined});

    return (
        <>
        <h1 className="text-xl pb-5">Mailgun</h1>
            <Tabs aria-label="Options">
                <Tab title="Routes">
                    {
                        user !== undefined && <>
                            <MailgunRoutesList user={user.data._id} />
                        </>
                    }
                </Tab>
                <Tab title="Keys">
                    {
                        user !== undefined && <>
                            <MailgunKeyList user={user.data._id}/>
                            <MailgunAddNewKey user={user.data._id}/>
                        </>
                    }

                </Tab>
            </Tabs>
        </>
    );
}

export default MailgunHome;
