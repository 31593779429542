
import { Table, TableBody, TableRow, TableHeader, TableColumn, TableCell, Select, Checkbox, Button } from '@nextui-org/react'
import { LocationEditAction } from '../reducers/LocationEditsReducer';
import { selectTimeOptions } from '../utils/datetime';

const HoursPeriod = (props) => {
    let { period, idx, day, count, dispatch } = props;

    return (
        <div className="flex flex-row items-center gap-1">
            <Select selectedKeys={[ period[0] ]} label="Open" onSelectionChange={(e) => {
                let value = Object.values(e)[0];
                dispatch({ type: LocationEditAction.HoursEditPeriod, day, idx, currentValues: period, start: value, end: period[1] });
            }}>{selectTimeOptions()}</Select>
            <Select selectedKeys={[ period[1] ]} label="Close" onSelectionChange={(e) => {
                let value = Object.values(e)[0];
                dispatch({ type: LocationEditAction.HoursEditPeriod, day, idx, currentValues: period, start: period[0], end: value });
            }}>{selectTimeOptions()}</Select>
            <Button color="danger" isIconOnly aria-label="Remove period" onPress={() => {
                if (count === 1) {
                    dispatch({ type: LocationEditAction.HoursToggleClosed, day, value: true });
                } else {
                    dispatch({ type: LocationEditAction.HoursRemovePeriod, day, idx, start: period[0], end: period[1] });
                }
            }}>X</Button>
        </div>
    );
}

const LocationHoursTable = (props) => {

    let { edit, dispatch, weekdays } = props;

    return (
        <>
        <Table isStriped className="p-1 py-4" aria-label='Regular Hours Table'>
            <TableHeader>
                <TableColumn>DAY</TableColumn>
                <TableColumn>OPEN</TableColumn>
                <TableColumn>IS CLOSED?</TableColumn>
            </TableHeader>
            <TableBody>
                { weekdays.map((day, idx) => {

                    const hours = edit.editable.hours.regular[day];
                    const { periods, isClosed } = hours;
                    const periodCount = periods.length;

                    return (
                        <TableRow key={day}>
                            <TableCell>{day}</TableCell>
                            <TableCell>
                                <div className="flex flex-col gap-4">
                                    { !isClosed && periods.map((period, idx) => {
                                        return <HoursPeriod period={period} day={day} idx={idx} count={periodCount} dispatch={dispatch}></HoursPeriod>
                                    }) }
                                    {! isClosed && <Button onPress={(e) => {
                                        dispatch({ type: LocationEditAction.HoursAddPeriod, day: day, newIdx: periods.length });
                                    }}>Add Period</Button>}
                                </div>
                            </TableCell>
                            <TableCell>
                                <Checkbox isSelected={hours.isClosed} onValueChange={(e) => {
                                    dispatch({ type: LocationEditAction.HoursToggleClosed, day: day, value: e });
                                }}></Checkbox>
                            </TableCell>
                        </TableRow>
                    )
                }) }
            </TableBody>
        </Table>
        </>
    )

}

export default LocationHoursTable;

