
import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query'
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, BrowserRouter, useNavigate, Routes, Route } from "react-router-dom"
import { NextUIProvider } from '@nextui-org/react'
import router from './router'
//import { Next } from 'react-bootstrap/esm/PageItem';
import Login from './Pages/Login';  
import Home from './Pages/Home';
import Accounts from './Pages/Accounts';
import Locations from './Pages/Locations';
import Location from './Pages/Location';
import Categories from './Pages/Categories';
import ResetPasswordRequest from './Pages/ResetPasswordRequest';

const queryClient = new QueryClient()

function App() {
    
  const navigate = useNavigate();

  return (

      <NextUIProvider navigate={navigate}>
        <QueryClientProvider client={queryClient}>
        <div className="App">
            {/*<RouterProvider router={router} />*/}
            <Routes>
              <Route path="/" element={<Login/>}/>
              <Route path="/reset-password" element={<ResetPasswordRequest/>}/>
              <Route path="/dashboard/home" element={<Home/>}/>
              <Route path="/dashboard/accounts" element={<Home/>}/>
              <Route path="/dashboard/categories" element={<Categories/>}/>
              <Route path="/dashboard/accounts/:accountId/page/:page" element={<Locations/>}></Route>
              <Route path="/dashboard/accounts/googleauth" element={<Accounts/>}></Route>
              <Route path="/dashboard/accounts/:accountId/locations/:locationId" element={<Location/>}></Route>
            </Routes>
            <ReactQueryDevtools position="bottom-right" />
        </div>
        </QueryClientProvider>
      </NextUIProvider>

  )
}

export default App;

/*
      <QueryClientProvider client={queryClient}>
          <NextUIProvider nagivate={navigate}>
          <div className="App">
              <RouterProvider router={router} />
              <ReactQueryDevtools position="bottom-right" />
          </div>
          </NextUIProvider>
      </QueryClientProvider>
    <BrowserRouter>
      <NextUIProvider nagivate={navigate}>
        <div className="App">
            <RouterProvider router={router} />
            <ReactQueryDevtools position="bottom-right" />
        </div>
      </NextUIProvider>
    </BrowserRouter>

*/
