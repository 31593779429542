import axios from 'axios'

const listAccounts = async () => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/accounts/list/`, config)
    return response.data
}

const syncGroups = async () => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/accounts/syncgroups/`, config)
    return response.data
}

const syncAll = async () => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/accounts/syncall/`, config);
    return response.data;

}

const groupDetails = async () => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/accounts/groupdetails/`, config)
    return response.data
}

export { listAccounts, syncGroups, syncAll, groupDetails }