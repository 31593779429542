
import CategoriesList from '../components/CategoriesList';

const Categories = () => {

    return (
        <div className="container mx-auto justify-center">
            <div className="row">
                <div className="w-12/12">
                    <h1 className="text-center">Categories</h1>
                </div>
                <div className="w-12/12">
                    <CategoriesList />
                </div>
            </div>
        </div>
    )

}

export default Categories;

