import axios from 'axios'

const listLocations = async ({queryKey}) => {
    const [_, account, group, page, accessToken] = queryKey

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            page: page,
            accessToken: accessToken,
            groupId : group
        }
    }

    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/locations/list/${account}/${group}`, config)
    return response.data
}



const getLocation = async ({queryKey}) => {
    const [_, locationId] = queryKey
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/locations/${locationId}`, config)
    return response.data
}



const syncLocations = async ({queryKey}) => {
    const [_, account] = queryKey

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/locations/sync/${account}`, config);
    return response.data;

}

const syncLocationsAdmins = async ({queryKey}) => {
    const [_, locationId] = queryKey

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/locations/sync/admins/${locationId}`, config);
    return response.data;

}



export { listLocations, getLocation, syncLocations, syncLocationsAdmins }