
import { useState } from 'react';
import { Input, Tab, Textarea, Button, CheckboxGroup, Checkbox, Spinner } from '@nextui-org/react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getUsers } from '../../endpoints/users';
import { getUser } from '../../endpoints/auth';
import { addKey } from '../../endpoints/mailgun';

const MailgunAddNewKey = (props) => {

    const { user: currentUser } = props;

    const [ formData, setFormData ] = useState({
        name: '',
        key: '',
        notes: '',
        managers: [ ],
        owners: [ ],
    });
    
    const { data: usersData } = useQuery({
        queryKey: [ 'mailgunAddKeyUsers' ],
        queryFn: getUsers,
        placeholderData: undefined,
    });

    const postKey = useMutation({
        mutationFn: addKey
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleOwnershipChange = (field, e) => {
        const newFormData = {
            ...formData,
            [field]: e
        };

        let newUser = newFormData[field].filter(v => !formData[field].includes(v));
        newUser = newUser.length > 0 ? newUser[0] : null;

        const otherField = field === 'owners' ? 'managers' : 'owners';

        if (newUser !== null) {
            newFormData[otherField] = newFormData[otherField].filter(v => v !== newUser);
        }

        setFormData(newFormData);
    }

    return (
        <>
        <div className="flex flex-col gap-5 pb-5">
            <h2 className="text-lg">Add new key</h2>
            <Input label="Name" name="name" onChange={handleChange}></Input>
            <Input label="API Key" name="key" onChange={handleChange}></Input>
            <Textarea label="Notes" name="notes" onChange={handleChange}></Textarea>
            <CheckboxGroup label="Add Owners" name="owners" value={formData.owners} onValueChange={e => {handleOwnershipChange('owners', e); }}>
                {
                    usersData && usersData.map((user, idx) => {
                        // Exclude the current user
                        if (user._id === currentUser) { return null; }

                        return (
                            <Checkbox key={`user-${idx}`} value={user._id}>{user.firstName} {user.lastName} ({user.email})</Checkbox>
                        )
                    })
                }
            </CheckboxGroup>
            <CheckboxGroup label="Add Managers" name="managers" value={formData.managers} onValueChange={e => {handleOwnershipChange('managers', e); }}>
                {
                    usersData && usersData.map((user, idx) => {
                        // Exclude the current user
                        if (user._id === currentUser) { return null; }

                        return (
                            <Checkbox key={`user-${idx}`} value={user._id}>{user.firstName} {user.lastName} ({user.email})</Checkbox>
                        )
                    })
                }
            </CheckboxGroup>            
        </div>
        <Button color='primary' onClick={() => {
            // Make sure the important fields are filled out
            if (formData.name.trim() === '' || formData.key.trim() === '') {
                return;
            }

            postKey.mutate({
                ...formData,
                user: currentUser
            });
        }}>Add New Key</Button>
        </>
    );
}

export default MailgunAddNewKey;
