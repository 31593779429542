
import MailgunKey from '../../components/mailgun/MailgunKey'

function Email() {    
    return (
        <section>
            <div className="container flex mx-auto">
                <div className="w-full px-4">
                    <main>
                        <MailgunKey />
                    </main>
                </div>
            </div>
        </section>
    )
}

export default Email;
