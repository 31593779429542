
import { Table, TableHeader, TableColumn, TableBody, TableCell, TableRow } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { getUserKeys } from '../../endpoints/mailgun';
import RouterLink from '../RouterLink';

const MailgunKeyList = (props) => {

    const { user } = props;

    const { data } = useQuery({
        queryKey: [ 'mailgunKeys', user ],
        queryFn: getUserKeys,
        placeholderData: undefined
    });

    return (
        <div className="flex flex-col gap-4 pb-5">
            <h2 className="text-lg">Your Mailgun Keys</h2>
            <Table aria-label="Mailgun Keys">
                <TableHeader>
                    <TableColumn>NAME</TableColumn>
                    <TableColumn>KEY</TableColumn>
                    <TableColumn>ACTIONS</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        data !== undefined && data.map((key, idx) => {
                            return (
                                <TableRow key={idx}>
                                    <TableCell>{key.name}</TableCell>
                                    <TableCell>{key.key}</TableCell>
                                    <TableCell>
                                        {
                                        key.owners.includes(user) && <RouterLink to={`/dashboard/emails/key/${key._id}`} state={{
                                            key: key
                                        }}>Edit</RouterLink>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </div>
    );
}

export default MailgunKeyList;
