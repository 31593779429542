
import { Link } from 'react-router-dom';

const RouterLink = (props) => {

    const { to, state, text } = props;

    const classes = "relative inline-flex items-center tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-medium text-primary no-underline px-2 py-1 hover:after:opacity-100 after:content-[''] after:inset-0 after:opacity-0 after:w-full after:h-full after:rounded-xl after:transition-background after:absolute hover:after:bg-primary/20";

    return (
        <Link className={classes} to={to} state={state}>{props.children}</Link>
    );

}

export default RouterLink;
