import {listLocations, syncLocations} from '../endpoints/googlebusinessprofile.locations'
import {useQuery, } from '@tanstack/react-query'
import { useParams, useLocation } from 'react-router-dom'
//import Table from 'react-bootstrap/Table';
//import Stack from 'react-bootstrap/Stack';
import { Button, ButtonGroup, Spinner } from '@nextui-org/react';

import { Table, TableBody, TableRow, TableHeader, TableColumn, TableCell } from '@nextui-org/react'

import RouterLink from './RouterLink'

const GoogleBusinessProfileLocations = () => {

    const { accountId, page } = useParams()
    const account = useLocation()
    const { accessToken } = account.state
    const { data, refetch } = useQuery({
        queryKey: ['googleBusinessLocations', accountId, account.state.groupId, page, accessToken],
        queryFn: listLocations,
        placeholderData: undefined
    });
    const { data: syncData, fetchStatus: syncFetchStatus, refetch: syncRefetch} = useQuery({
        queryKey: ['googleBusinessLocations', accountId, accessToken], 
        queryFn: syncLocations,
        placeholderData: undefined,
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });
    

    
    const onSync = () => {
        syncRefetch().then(res => {
            // We want to ensure that we pull from mongo directly after a sync.
            refetch()
        });
    };

    function formatDate(pHours, pMinutes) {
        let hours = pHours || '0';
        let minutes = pMinutes || '0';

        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
    
        // Find current hour in AM-PM Format
        hours = hours % 12;
    
        // To display "0" as "12"
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
    
        return hours + ':' + minutes + ' ' + newformat
    }

    const formatAdmins = admins => {
        if (!Array.isArray(admins)) {
            return <p>No admins for this location.</p>
        }

        // Group by the admin role
        let grouped = { };
        admins.forEach(admin => {
            if (grouped[admin.role] === undefined) {
                grouped[admin.role] = [];
            }
            grouped[admin.role].push(admin.admin);
        })

        // Sort each alphabetically
        Object.keys(grouped).forEach(key => {
            grouped[key] = grouped[key].sort();
        })

        return (
            <ul className="list-disc">
                {
                    Object.keys(grouped).sort().map(role => {
                        return (
                            <li>
                                <h4>{role}</h4>
                                <ul className="list-disc pl-4">
                                    {
                                        grouped[role].map(admin => {
                                            return <li>{admin}</li>
                                        })
                                    }
                                </ul>
                            </li>
                        )
                    
                    })
                }
            </ul>
        )

    }

    return(
        <>
        <div className="flex items-center py-4 gap-4">
            { data && <Button color="primary" onClick={onSync} isLoading={syncFetchStatus === 'fetching'}>Sync Locations</Button> }
            { syncFetchStatus === 'fetching' && <span>Syncing... This might take a while...</span> }
        </div>
        <Table isStriped>
            <TableHeader>
                <TableColumn>LOCATION</TableColumn>
                <TableColumn>DETAILS</TableColumn>
                <TableColumn>ADMINS</TableColumn>
            </TableHeader>
            <TableBody emptyContent={<Spinner/>}>
                {data &&
                    data.sort((a, b) => a.title.localeCompare(b.title)).map((location, idx) => {
                        let street= location.storefrontAddress?.addressLines.join(' ') || '';
                        let address = `${street} ${location.storefrontAddress?.locality}, ${location.storefrontAddress?.administrativeArea} ${location.storefrontAddress?.postalCode}`;

                        return(
                            <TableRow key={idx}>
                                <TableCell>
                                    <div className="flex flex-col items-start">
                                        <span className="location_storecode">{location.storeCode}</span>
                                        <h5 className="location_storecode">{location.title}</h5>
                                        <h4 className="location_id">{location.name}</h4>
                                        <RouterLink to={`/dashboard/accounts/${accountId}/${location.name}`} state={{accessToken: accessToken}}>{location.title}</RouterLink>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-col gap-2">
                                        <span>{address}</span>
                                        {location.phoneNumbers.map((phone, idx) => {
                                            return (
                                                <span>{phone.primaryPhone}</span>
                                            )
                                        })}
                                        <span className="website">
                                        {
                                        location?.websiteUri && 
                                            <a href={location.websiteUri}>
                                                {location.websiteUri.split("?")[0]}
                                            </a>    
                                        }
                                        </span>
                                        <span className="hours">
                                            {location?.regularHours?.periods.map((hours, idx) => {
                                                return (
                                                    <div className="hours__day">
                                                        {hours.openDay}: {formatDate(hours?.openTime?.hours, hours?.openTime?.minutes)} - {formatDate(hours?.closeTime?.hours, hours?.closeTime?.minutes)}
                                                    </div>
                                                )
                                            })}
                                        </span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    { location && formatAdmins(location.locationAdmins) }
                                    {/*true || !Array.isArray(location.locationAdmins) ? <p>No admins for this location.</p> : location?.locationAdmins.map((admin, idx) => {
                                        return (
                                            <div key={location._id.toString() + '_' + admin.name.split('/')[3]} className="location__admin">
                                                {admin.admin} - {admin.role}
                                            </div>
                                        )
                                    })*/}
                                </TableCell>
                            </TableRow>
                        )
                    })
                } 
            </TableBody>
        </Table>
        </>
    );
}

export default GoogleBusinessProfileLocations