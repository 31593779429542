
import { useQuery, useMutation } from '@tanstack/react-query';
import { getKeys, createKey, revokeKey } from '../endpoints/keys';

import { Table, TableHeader, TableColumn, TableBody, TableCell, TableRow } from '@nextui-org/react';
import { Button, Input, Spinner } from '@nextui-org/react';

const ApiKeyEditForm = (props) => {

    const edit = useMutation({
        
    })

    return (
        <div className="flex flex-col gap-4">
            <form>
                <Input label="Name"> </Input>
                <div className="flex justify-between">
                    <Button color="danger" >Cancel</Button>
                    <Button color="primary">Confirm</Button>
                </div>
            </form>
        </div>
    )

}

const ApiKeysList = () => {

    const { data: keyData, fetchStatus: keyFetchStatus, refetch: keyRefetch } = useQuery({
        queryKey: [ 'keys' ],
        queryFn: getKeys,
        placeholderData: [ ]
    });

    const create = useMutation({
        mutationFn: createKey,
        onSuccess: () => {
            keyRefetch();
            create.reset();
        }
    })
    const revoke = useMutation({
        mutationFn: revokeKey,
        onSuccess: () => {
            keyRefetch();
            revoke.reset();
        }
    });
    
    const onAddKey = (e) => {
        e.preventDefault();
        create.mutate(document.getElementById('keyName').value)
        document.getElementById('keyName').value = '';
    }

    const revokeAllKeys = () => {
        keyData.forEach((key) => {
            revoke.mutate(key._id);
        });
    }

    return (
        <div className="flex flex-col gap-4">
            <h3 className="text-large">Your API Keys:</h3>
            <Table isStriped aria-label="Example static collection table" emptyContent={<Spinner/>}>
                <TableHeader>
                    <TableColumn>NAME</TableColumn>
                    <TableColumn>KEY</TableColumn>
                    <TableColumn>ACTIONS</TableColumn>
                </TableHeader>
                <TableBody>
                    { keyData.map((key, idx) => {
                        return (
                            <TableRow key={idx}>
                                <TableCell>{key.label}</TableCell>
                                <TableCell>{key.key}</TableCell>
                                <TableCell>
                                    <div className="flex gap-2">
                                        <Button color="danger" type="button" onClick={() => { revoke.mutate(key._id); }}>Revoke</Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    }) }
                </TableBody>
            </Table>
            <form className="container mx-auto"> 
                <div className="flex flex-col w-12/12 gap-4 py-4">
                    <p className="text-large">Add new key</p>
                    <Input id="keyName" label="Name" />
                    <div className="flex justify-between py-2">
                        <Button color="primary" onClick={onAddKey}>Generate Key</Button>
                        <Button color="danger" onClick={revokeAllKeys}>Revoke All Keys</Button>
                    </div>   
                </div>
            </form>   
        </div>
    );
}

export default ApiKeysList;
