//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import {useNavigate} from 'react-router-dom'

import { passwordReset } from '../endpoints/auth'

import { Input, Button } from '@nextui-org/react';
import { useParams, useLocation } from 'react-router-dom'

const resetObject = {
    password: { value: '', isInvalid: false, error: '' },
    passwordConfirm: { value: '', isInvalid: false, error: '' }
}

// const requirementObject = {
//     isInvalid: true,
//     match: { value: true, message: 'Passwords must match' },
//     minLength: { value: false, message: 'Password must be at least 8 characters long' }
// }

// const checkPasswordSafety = password => {
//     if (password.length < 8) {
//         return { status: 'error', message: 'Your password must be at least 8 characters long' };
//     }

//     return { status: 'success', message: '' };
// }

function ResetPasswordForm() {

    const { token } = useParams();

    const [ hasBeenReset, setHasBeenReset ] = useState(false);
    const [formData, setFormData] = useState(resetObject)
    //const [ requirements, setRequirements ] = useState(requirementObject);

    const handleInputChange = (value, field) => {
        const newFormData = {
            ...formData,
            [field]: { ...formData[field], value }
        };

        // Wipe the invalid state
        Object.keys(newFormData).forEach(key => {
            newFormData[key].isInvalid = false;
            newFormData[key].error = '';
        })

        setFormData(newFormData);

        // const newRequirements = { ...requirements };

        // if (newFormData.password.value !== newFormData.passwordConfirm.value) {
        //     newRequirements.match.value = false;
        // } else if (newFormData.password.value.length < 8) {
        //     newRequirements.minLength.value = false;
        // }
        // setRequirements(newRequirements)

    }
    
    const navigate = useNavigate();

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: passwordReset,
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: [ 'register' ] })
            queryClient.invalidateQueries({ queryKey: [ 'passwordReset' ] })
            //navigate('/');
            hasBeenReset(true);
            setFormData(resetObject);
        },
    })

    const submitReset = e => {
        e.preventDefault();

        if (formData.password.value !== formData.passwordConfirm.value) {
            setFormData({
                ...formData,
                passwordConfirm: { ...formData.passwordConfirm, isInvalid: true, error: 'Passwords do not match' }
            });
            return;
        }

        // Length check
        if (formData.password.value.length < 8) {
            setFormData({
                ...formData,
                passwordConfirm: { ...formData.passwordConfirm, isInvalid: true, error: 'Password must be at least 8 characters long' }
            });
            return;
        }
        mutation.mutate({
            token,
            password: formData.password.value
        });
        navigate('/');
    }

    return (
        <>
        <div className="container flex justify-center"> 
            <div className="reset-password-form">
                <form className="flex flex-col gap-4 p-4 bg-secondary rounded-lg" onSubmit={submitReset}>
                    <div className="flex flex-row gap-4">
                        <p className="text-white">Enter a new password.</p>
                    </div>
                    <div className="flex flex-col gap-4">
                        <Input isRequired
                            id="password"
                            type="password"
                            label="New Password"
                            name="password"
                            value={formData.password.value}
                            onValueChange={e => handleInputChange(e, 'password')} />
                        <Input isRequired
                            isInvalid={formData.passwordConfirm.isInvalid}
                            errorMessage={formData.passwordConfirm.error}
                            id="passwordConfirm"
                            type="password"
                            label="Confirm Password"
                            name="passwordConfirm"
                            value={formData.passwordConfirm.value}
                            onValueChange={e => handleInputChange(e, 'passwordConfirm')} />
                    </div>
                    {/* <ul className="password-requirements">
                    {
                        Object.keys(requirements).map((requirement, idx) => {
                            if (requirement === 'isInvalid') return;

                            const { value, message } = requirements[requirement];

                            return (<li key={idx} className={`password-requirement${value ? ' fulfilled' : '' }`}>{message}</li>) 
                        })
                    }
                    </ul> */}
                    <Button type="submit" color="primary">Submit</Button>
                    {
                        hasBeenReset ? <p className="text-white">Your password has been reset</p> : null
                    }
                </form>
            </div>
        </div>
        </>
    );
}

export default ResetPasswordForm;