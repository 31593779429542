
import { useQuery, useMutation } from '@tanstack/react-query';
import { getRoute } from '../../endpoints/mailgun';
import {Link, useParams, useLocation} from 'react-router-dom'
import BackButton from '../BackButton';
import { Table, TableBody, TableColumn, TableHeader, TableCell, TableRow, Textarea, Button, Input } from '@nextui-org/react';
import { useState, useEffect } from 'react';
import { updateRoute } from '../../endpoints/mailgun';
import { getUser } from '../../endpoints/auth';

const MailgunRouteEditsSummary = (props) => {

    const { initial, current, setCurrent, setInitial, refetch, user, description } = props;

    const location = useLocation();

    // Mutation for updating route
    const routeUpdate = useMutation({
        mutationFn: updateRoute
    });

    return (
        <div className="flex flex-col gap-4">
            <h1 className="text-xl">Review your edits to this route</h1>
            <Table label="">
                <TableHeader>
                    <TableColumn>EDITS</TableColumn>
                    <TableColumn>ACTIONS</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        initial !== undefined && initial.map((recipient, idx) => {
                            // List all of the initial recipients that are not in the current list
                            const inCurrent = current.indexOf(recipient) !== -1;
                            if (inCurrent) { return null; }

                            return (
                                <TableRow key={`recipient-${idx}`}>
                                    <TableCell>Removed "{recipient}"</TableCell>
                                    <TableCell><Button color="danger" onClick={e => {
                                        e.preventDefault();
                                        setCurrent([ ...current, recipient ]);
                                    }}>X</Button></TableCell>
                                </TableRow>
                            );
                        })
                    }
                    {
                        current !== undefined && current.map((recipient, idx) => {
                            // List all of the newly added recipients
                            const inInitial = initial.indexOf(recipient) !== -1;
                            if (inInitial) { return null; }
                            
                            return (
                                <TableRow key={`recipient-${idx + initial.length}`}>
                                    <TableCell>Added "{recipient}"</TableCell>
                                    <TableCell><Button color="danger" onClick={e => {
                                        e.preventDefault();
                                        let newForwards = [ ...current ];
                                        let idx = newForwards.indexOf(recipient);
                                        newForwards.splice(idx, 1);
                                        setCurrent(newForwards);
                                    }}>X</Button></TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
            { <div> <Button color="primary" onClick={async () => {
                let result = routeUpdate.mutate({
                    id: props.route.id,
                    description: description,
                    expression: props.route.expression,
                    priority: props.route.priority,
                    recipients: current,
                    key: location.state.key
                });
                // Reset the edit state
                setInitial(undefined);
                
                setTimeout(refetch, 1000);

            }}>Confirm and save edits</Button> </div> }
        </div>
    );

}

const MailgunRoute = () => {

    const location = useLocation();
    const { routeId } = useParams();

    const { data, refetch } = useQuery({
        queryKey: [ 'mailgunRoute', routeId, location.state.key ],
        queryFn: getRoute,
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        onSuccess: res => {
            if (initialForwards !== undefined) { return; }
            let actions = res.actions.filter(v => v.startsWith('forward(')).map(forward => {
                return forward.split('"')[1].split(',').map(v => v.trim());
            }).flat();
    
            setForwards(actions);
            setInitialForwards(actions);
            setDescription(res.description);
            setInitial(res.route);
         }
    });

    const { data: user } = useQuery({ queryKey: [ 'user' ], queryFn: getUser, placeholderData: undefined});

    const [ forwards, setForwards ] = useState(undefined);
    const [ initialForwards, setInitialForwards ] = useState(undefined);
    const [ forwardAdd, setForwardAdd ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ initial, setInitial ] = useState(undefined);

    const haveChanges = (initial, current) => {
        if (initial.length !== current.length) { return true; }
        return initial.sort().toString() !== current.sort().toString();
    }
    
    return (
        <>
            <BackButton />
            <h1 className="text-xl pb-4">{data !== undefined ? data.expression.split('"')[1] : ''}</h1>
            <div className="flex flex-col gap-4 pb-6">
                <Table aria-label="Forwards">
                    <TableHeader>
                        <TableColumn>FORWARDS</TableColumn>
                        <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            forwards !== undefined && forwards.length == 0 ?
                            <TableRow>
                                <TableCell>No recipients</TableCell>
                                <TableCell></TableCell>
                            </TableRow> : null
                        }
                        {
                            forwards !== undefined && forwards.map((recipient, idx) => {
                                return (
                                    <TableRow key={`recipient-${idx}`}>
                                        <TableCell>{recipient}</TableCell>
                                        <TableCell>
                                            <Button onClick={e => {
                                                e.preventDefault();
                                                let newForwards = [ ...forwards ];
                                                let idx = newForwards.indexOf(recipient);
                                                newForwards.splice(idx, 1);
                                                setForwards(newForwards);
                                            }}>Remove</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                { data !== undefined && <Textarea label="Description" value={data.description}></Textarea> }
            </div>
            <div className="flex flex-col gap-4 pb-6">
                <h1 className="text-xl">Add new</h1>
                <Input label='Email Address' type="email" value={forwardAdd} onValueChange={setForwardAdd}></Input>
                <div>
                    <Button onClick={e => {
                        e.preventDefault();
                        setForwards([ ...forwards, forwardAdd ]);
                        setForwardAdd('');
                    }}>Add new forward address</Button>
                </div>
            </div>
            {
                forwards !== undefined && initialForwards !== undefined && haveChanges(initialForwards, forwards) ? 
                <MailgunRouteEditsSummary
                    user={user}
                    route={data}
                    refetch={refetch}
                    description={description}
                    initialRoute={initial}
                    initial={initialForwards}
                    setInitial={setInitialForwards}
                    current={forwards}
                    setCurrent={setForwards} />
                : null
              
            }
        </>
    );

}

export default MailgunRoute;
