
import { useQuery } from '@tanstack/react-query';
import { useState, useMemo, useRef } from 'react';
import { Slider } from '@nextui-org/react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import { listReviews } from '../endpoints/googlebusinessprofile.reviews';

const chartOptions = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right'
        },
        title: {
            display: true,
            text: 'Reviews: Common Words',
        }
    }
};

// These are common words which we want to remove from the analysis
const removals = [
    "i", "and", "the", "to", "my", "", "a", "is", "of", "so", "for", "me", "with", "she", "in", "was", "you", "have", "they", "it", "that", "this", "had", "your", "an", "he", "her", "on", "be", "at", "what", "am", "i've"
];

const formatChartData = (data, minRating, occurrenceThreshold) => {

    if (!data) { return [ ]; }

    const ledger = { };

    data.reviews.filter(v => v.starRating <= minRating).forEach(review => {
        if (!review.comment) { return; }
        let { comment } = review;

        // Remove punctuation
        comment = comment.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()…]/g, "");
        // Remove newlines
        comment = comment.replace(/\n/g, " ");

        const words = comment.toLowerCase().split(" ").filter(v => !removals.includes(v));

        words.forEach(word => {
            if (ledger[word]) {
                ledger[word]++;
            } else {
                ledger[word] = 1;
            }
        })
    });

    // Once we have the data in ledger form, we can massage it into the format that react-charts wants
    // We can be efficient and remove the keys from the ledger as we process them.
    const finalData = Object.keys(ledger).filter(v => ledger[v] >= occurrenceThreshold[0] && ledger[v] <= (occurrenceThreshold[1] === 25 ? Infinity : occurrenceThreshold[1])).sort((a, b) => ledger[b] - ledger[a]).map(v => ({ word: v, count: ledger[v] }));

    return {
        labels: finalData.map(v => v.word),
        datasets: [
            {
                label: 'Word Occurrences',
                data: finalData.map(v => v.count),
                backgroundColor: '#3aad87',
                borderColor: '#2e8a6b',
            }
        ]
    }
}

const ReviewsCommonWordsChart = props => {

    const { accountId, locationId } = props;

    const ref = useRef();

    const [ minRating, setMinRating ] = useState(5);

    const [ occurrenceThreshold, setOccurrenceThreshold ] = useState([ 10, 25 ]);

    const { data, refetch } = useQuery([ 'googleBusinessReviews', accountId, locationId ], listReviews);
    
    const chartData = useMemo(() => formatChartData(data, minRating, occurrenceThreshold), [ data, minRating, occurrenceThreshold ]);

    return (
        <>
        <div className="flex flex-col p-1 py-4">
            <p>Min Rating: {minRating}</p>
            <div className="flex w-6/12">
                <Slider value={minRating} showSteps showTooltip minValue={1} maxValue={5} onChange={setMinRating}></Slider>
            </div>
            <p>Occurrence Threshold: {occurrenceThreshold[0]} - {occurrenceThreshold[1]}{occurrenceThreshold[1] === 25 ? '+' : ''}</p>
            <div className="flex w-6/12">
                <Slider value={occurrenceThreshold} defaultValue={occurrenceThreshold} showSteps showTooltip minValue={1} maxValue={25} onChange={setOccurrenceThreshold}></Slider>
            </div>
        </div>
        { data && chartData && (
            <div className={`h-[${chartData.datasets[0].data.length*16}px]`} style={{ height: `${chartData.datasets[0].data.length*24}px`, minHeight:`${24*13}px` }}>
                <div className={`chart-container flex relative w-100% h-full`}> 
                    {chartData.datasets[0].data.length === 0 ? 
                        <em>No data for selected range.</em> :
                        <Bar responsive ref={ref} key="reviewCommonWordsChart" data={chartData} options={chartOptions}/>
                    }
                </div>
            </div>
        ) }
        </>
    );
}

export default ReviewsCommonWordsChart;
