import axios from 'axios'

const getAccounts = async (req) => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/accounts/`, config)

    return response.data
}

export { getAccounts }