import { Navbar, NavbarBrand, NavbarContent, NavbarItem } from '@nextui-org/react'
import { Link } from 'react-router-dom'

const LoggedOutHeader = () => {

    return (
        <>
        <Navbar>
            <NavbarBrand>
                <Link to="/">WCN Dashboard</Link>
            </NavbarBrand>
            <NavbarContent justify="left">
                <div className="logo-wrapper">
                    <img className='logo' alt="Logo" src={require('../assets/images/logo-square.png')}/>
                </div>
                <NavbarItem>
                    
                </NavbarItem>
            </NavbarContent>
        </Navbar>
        </>
    )
}

export default LoggedOutHeader
