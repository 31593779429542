
import { Button, Input, Textarea, Table, TableHeader, TableColumn, TableRow, TableBody, TableCell, Select, SelectItem, Spinner } from '@nextui-org/react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { getUserKeys, createRoute } from '../../endpoints/mailgun';
import { getUser } from '../../endpoints/auth';
import BackButton from '../BackButton';

const MailgunAddNewRouteForm = (props) => {

    const { user } = props;

    const { data: keys } = useQuery({
        queryKey: [ 'mailgunUserKeys', user ],
        queryFn: getUserKeys,
        placeholderData: undefined
    });

    const postRoute = useMutation(createRoute, {
        mutationFn: createRoute,
    });

    const [ data, setData ] = useState({
        forwards: [ ],
        recipient: '',
        key: new Set([ ])
    });
    const [ newForward, setNewForward ] = useState('');

    const canAdd = function() {
        // Ensure key is set
        if (data.key.size !== 1) { return false; }

        // Ensure recipient is set
        if (data.recipient.trim() === '') { return false; }

        // Ensure forwards are set, none are empty
        if (data.forwards.length === 0) { return false; }
        if (data.forwards.map(v => v.trim()).includes('')) { return false; }

        return true;
    }

    return (
        <div className="flex flex-col gap-4">
            <Select label="Account" description="Select the account to associate the new route with." selectedKeys={data.key} onSelectionChange={e => {
                const newData = { ...data, key: e };
                setData(newData);
            }}>
            {
                keys && keys.map((key, index) => {
                    return (
                        <SelectItem key={`key-${index}`} value={key.key}>{key.name}</SelectItem>
                    )
                })
            }
            </Select>
            <Input label="Recipient" description="Enter the email to match if an email was sent to the specified recipient." value={data.recipient} onChange={e => {
                setData({ ...data, recipient: e.target.value });
            }}></Input>
            <Table aria-label="New route forwards">
                <TableHeader>
                    <TableColumn>FORWARD ADDRESS</TableColumn>
                    <TableColumn>ACTIONS</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        data.forwards.map((forward, index) => {
                            return (
                                <TableRow key={`forward-${index}`}>
                                    <TableCell>
                                        <Input type="email" value={data.forwards[index]} onValueChange={e => {
                                            let newForwards = [ ...data.forwards ];
                                            newForwards[index] = e;
                                            setData({ ...data, forwards: newForwards });
                                        }}/>
                                    </TableCell>
                                    <TableCell>
                                        <Button color="danger" onClick={e => {
                                            e.preventDefault();
                                            let newForwards = [ ...data.forwards ];
                                            newForwards.splice(index, 1);
                                            setData({ ...data, forwards: newForwards });
                                        }}>X</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    <TableRow>
                        <TableCell>
                            <Input type="email" description="Forwards the message to a specified destination, which can be another email address or a URL. You can add multiple destinations by adding each as a row." label="Forward Address" value={newForward} onValueChange={setNewForward}></Input>
                        </TableCell>
                        <TableCell>
                            <Button onClick={e => {
                                e.preventDefault();

                                if (newForward.trim() === '') { return; }

                                setData({ ...data, forwards: [ ...data.forwards, newForward ] });
                                setNewForward('');
                            }}>Add</Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            { canAdd() && <div>
                    <Button color="primary" onClick={e => {
                        e.preventDefault();

                        // Push new route
                        const body = {
                            key: keys[parseInt(Array.from(data.key)[0].split('-')[1])].key,
                            recipient: data.recipient,
                            forwards: data.forwards
                        }
                        postRoute.mutate(body);

                        // reset the form
                        setData({
                            forwards: [ ],
                            recipient: '',
                            key: new Set([ ])
                        });
                        setNewForward('');

                    }}>Add New Route</Button>
                </div>
            }
        </div>
    )

}


const MailgunAddNewRoute = () => {

    const { data: user } = useQuery({ queryKey: [ 'user' ], queryFn: getUser, placeholderData: undefined});

    return (
        <div className="flex flex-col gap-4">
            <BackButton />
            <h1 className="text-xl"> Add New Route </h1>
            { user === undefined ? <Spinner/> : <MailgunAddNewRouteForm user={user.data._id}/> }
        </div>
    )

}

export default MailgunAddNewRoute;
