
import { Table, TableBody, TableRow, TableHeader, TableColumn, TableCell, Input, Select, SelectItem, Checkbox, Button, Tooltip } from '@nextui-org/react'
import { useState } from 'react';
import { LocationEditAction } from '../reducers/LocationEditsReducer';
import { monthDayCounts, selectTimeOptions } from '../utils/datetime';

const AddSpecialHoursForm = (props) => {

    const { dispatch } = props;

    let [ newSpecialHour, setNewSpecialHour ] = useState({
        month: "",
        day: "",
        year: "",
        isClosed: false,
        isValid: false,
        validationMessage: ""
    });

    const handleSpecialHourChange = (field, newValue) => {

        const checkDateVailidity = (month, day, year) => {
            // Check at all fields are populated
            if (month === undefined || month === "") { return { isValid: false, validationMessage: "Invalid month" }; }
            if (day === undefined || day === "") { return { isValid: false, validationMessage: "Invalid day"}; }
            if (year === undefined || year === "") { return { isValid: false, validationMessage: "Invalid year" }; }

            // Check that the day is valid for the given month
            if (day > monthDayCounts[month - 1]) { return { isValid: false, validationMessage: "Invalid day" }; }

            // Ensure that the year is 4 digits
            if (year.length !== 4) { return { isValid: false, validationMessage: "Invalid year"}; }

            return { isValid: true, validationMessage: "" };
        }

        const newState = { ...newSpecialHour };

        if (field === 'month') {
            newState.month = newValue;

            // Ensure that the day gets adjusted if needed
            if (newState.day > monthDayCounts[newValue - 1]) { 
                newState.day = monthDayCounts[newValue - 1];
            }

        } else if (field === 'day') {
            newState.day = newValue;
        } else if (field === 'year') {
            newState.year = newValue;
        }
        
        // Do some validity checks
        let { isValid, validationMessage }  = checkDateVailidity(newState.month, newState.day, newState.year);
        setNewSpecialHour({ ...newState, isValid, validationMessage });
    }

    return (
        <>
        <div className="flex flex-col gap-2 p-1 py-4">
            <h3>Add new</h3>
            <div className="flex flex-row gap-2">
                <Select selectedKeys={newSpecialHour.month === "" ? [ ] : [ newSpecialHour.month ]} label="Month" onSelectionChange={(e) => {
                    let value = Object.values(e)[0];
                    handleSpecialHourChange('month', value);
                }}>
                    { Array.from(Array(12).keys()).map(v => <SelectItem key={`${v + 1}`} value={`${v + 1}`}>{`${v + 1}`}</SelectItem>) }
                </Select>
                <Select label="Day" selectedKeys={newSpecialHour.day === "" ? [ ] : [ newSpecialHour.day ]} onSelectionChange={e => {
                    let value = Object.values(e)[0];
                    handleSpecialHourChange('day', value);
                }}>
                    { Array.from(Array(31).keys()).map(v => <SelectItem key={`${v + 1}`} value={`${v + 1}`}>{`${v + 1}`}</SelectItem>) }
                </Select>
                <Input label="Year" value={newSpecialHour.year} onValueChange={(e) => {
                    handleSpecialHourChange('year', e);
                }}></Input>
            </div>
            <Tooltip isDisabled={newSpecialHour.validationMessage === ""} color="danger" placement='right' content={newSpecialHour.validationMessage}>
                <span>
                    <Button isDisabled={!newSpecialHour.isValid} onPress={() => {
                        if (!newSpecialHour.isValid) { return; }
                        const key = `${newSpecialHour.year}-${newSpecialHour.month}-${newSpecialHour.day}`;
                        dispatch({ type: LocationEditAction.SpecialHoursAdd, date: key });
                        setNewSpecialHour({
                            month: undefined,
                            day: undefined,
                            year: undefined,
                            isClosed: false,
                            isValid: false
                        })
                    }}>Add new Special Hours</Button>
                </span>
            </Tooltip>
        </div>
        </>
    )

}

const LocationSpecialHoursTable = (props) => {

    let { edit, dispatch } = props;

    return (
        <>
        <Table isStriped className="p-1 py-4" aria-label='Special Hours Table'>
            <TableHeader>
                <TableColumn>DATE</TableColumn>
                <TableColumn>HOURS</TableColumn>
                <TableColumn>IS CLOSED?</TableColumn>
                <TableColumn>ACTIONS</TableColumn>
            </TableHeader>
            <TableBody>

                { Object.keys(edit.editable.hours.special).filter(v => !edit.editable.hours.special[v].markedAsRemoved).map((day, idx) => {
                    let special = edit.editable.hours.special[day];
                    
                    return (
                        <TableRow>
                            <TableCell>{day}</TableCell>
                            <TableCell>
                                { !special.isClosed &&
                                <div className="flex flex-row gap-2 min-w-40">
                                    <Select label="Open" selectedKeys={[ edit.editable.hours.special[day].open ]} onSelectionChange={e => {
                                        let value = Object.values(e)[0];
                                        dispatch({ type: LocationEditAction.SpecialHoursEditPeriod, date: day, field: "open", value });
                                    }}>{selectTimeOptions()}</Select>
                                    <Select label="Close" selectedKeys={[ edit.editable.hours.special[day].close ]} onSelectionChange={e => {
                                        let value = Object.values(e)[0];
                                        dispatch({ type: LocationEditAction.SpecialHoursEditPeriod, date: day, field: "close", value });
                                    }}>{selectTimeOptions()}</Select>
                                </div> }
                            </TableCell>
                            <TableCell>
                                <Checkbox isSelected={edit.editable.hours.special[day].isClosed} onValueChange={e => {
                                    dispatch({ type: LocationEditAction.SpecialHoursToggle, date: day, field: "isClosed", value: e });
                                }}></Checkbox>
                            </TableCell>
                            <TableCell>
                                <Button color="danger" isIconOnly onPress={() => {
                                    dispatch({ type: LocationEditAction.SpecialHoursRemove, date: day });
                                }}>X</Button>
                            </TableCell>
                        </TableRow>
                    );
                }) }

            </TableBody>
        </Table>
        <AddSpecialHoursForm dispatch={dispatch}/>
        </>
    )

}

export default LocationSpecialHoursTable;
