import axios from 'axios'



const listReviews = async ({queryKey}) => {
    const [_, accountId, locationId, accessToken] = queryKey

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            account: accountId,
            location: locationId,
            accessToken: accessToken
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/reviews/list/${locationId}`, config)
    return response.data
}



const getReview = async ({queryKey}) => {
    const [_, location, reviewId] = queryKey
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/reviews/${location}/${reviewId}`, config)
    return response.data
}



const syncReviews = async ({queryKey}) => {
    const [_, account, location] = queryKey

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            account: account
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/reviews/sync/${location}`, config);
    return response.data;
}




const queryReviews = async ({queryKey}) => {
    const [ _, query ] = queryKey;
    const { search, rating, author, user } = query;

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            search,
            rating: rating.length === 1 && rating[0] === 'any' ? 'any' : rating,
            author,
            user: user
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/reviews/query`, config);
    return response.data;
}

export { listReviews, getReview , syncReviews, queryReviews }