import {
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { Link, Outlet } from 'react-router-dom'
import LoggedOutHeader from '../components/LoggedOutHeader'

const queryClient = new QueryClient()

function Home() {
    
  return (
    <QueryClientProvider client={queryClient}>
        <LoggedOutHeader />
        <section className="main-content">
            <div className="homepage">
                <div className="container mx-auto justify-center">
                    {/*<div className="row">*/}
                        <div className="w-12/12">
                            <h1 className="text-center">Welcome</h1>
                        </div>
                        <div className="w-12/12">
                            <Outlet />
                        </div>
                    {/*</div>*/}
                </div>
            </div>
        </section>
    </QueryClientProvider>
  )
}

export default Home;
