
import axios from 'axios'

const getUsers = async (req) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/users/`, config)
    return response.data.user;
}

export { getUsers };