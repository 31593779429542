import { Link as RouterLink, useNavigate } from 'react-router-dom' 
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Image } from '@nextui-org/react'
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import {logout} from '../endpoints/auth'
//import {useNavigate} from 'react-router-dom'
import {getUser} from '../endpoints/auth'
import { Button, ButtonGroup, Link } from '@nextui-org/react'
//import { Navbar } from 'react-bootstrap'

const LoggedInHeader = () => {

    const queryClient = useQueryClient()

    const {data} = useQuery({queryKey: ['user'], queryFn: getUser })

    const navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: logout,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['login'] })

        }
    })

    const submitLogout = (e) => {
        e.preventDefault()
        navigate('/login')
        mutation.mutate({ user: data })
    }

    return (
        <Navbar maxWidth='full' isBordered>
            <NavbarContent className="hidden sm:flex gap-4" justify="start">
                <div className="logo-wrapper">
                    <img className='logo' alt="Logo" src={require('../assets/images/logo-square.png')}/>
                </div>
                <NavbarItem>
                    <Link color="black" href="/dashboard/home">WCN Dashboard</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link isBlock href="/dashboard/home">Home</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link isBlock href="/dashboard/accounts">Accounts</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link isBlock href="/dashboard/categories">Categories</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link isBlock href="/dashboard/emails">Emails</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link isBlock href="/dashboard/review-query">Review Query</Link>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent className="hidden sm:flex gap-4" justify="end">
                <NavbarItem>
                { data ?
                    <div className="flex items-center gap-4">
                        <span className="name">{data.data.firstName} {data.data.lastName}</span>
                        <Button color="primary" onClick={submitLogout}>Logout</Button>
                    </div> :
                    <span><RouterLink to="/login/">Login</RouterLink></span>
                }
                </NavbarItem>
            </NavbarContent>
        </Navbar>

    )
}

export default LoggedInHeader