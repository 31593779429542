
import { Button } from '@nextui-org/react'; 
import { useNavigate } from 'react-router-dom';

const BackButton = () => {

    const navigate = useNavigate();

    return (
        <div className="pb-4">
            <Button size="small" auto onClick={() => { navigate(-1) }}>
                Back
            </Button>
        </div>
    );

}

export default BackButton;
