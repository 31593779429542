
import { useQuery, useMutation } from '@tanstack/react-query';
import { getMailgunKey, updateMailgunKey } from '../../endpoints/mailgun';
import { getUsers } from '../../endpoints/users';
import { getUser } from '../../endpoints/auth';
import { Input, Textarea, CheckboxGroup, Checkbox, Spinner, Button, Table, TableHeader, TableColumn, TableCell, TableBody, TableRow } from '@nextui-org/react';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import BackButton from '../BackButton';

const MailgunKey = () => {

    const params = useParams();

    const { data, refetch } = useQuery({
        queryKey: [ 'mailgunKey', params.keyId ],
        queryFn: getMailgunKey,
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        onSuccess: data => {
            setInitial(data);
            setCurrent(data);
        }
    });

    const { data: usersData } = useQuery({
        queryKey: [ 'mailgunAddKeyUsers' ],
        queryFn: getUsers,
        placeholderData: undefined
    });

    const putKey = useMutation({
        mutationFn: updateMailgunKey
    });

    const { data: currentUser } = useQuery({ queryKey: [ 'user' ], queryFn: getUser, placeholderData: undefined});
    
    const [ initial, setInitial ] = useState(undefined);
    const [ current, setCurrent ] = useState(undefined);

    const handleChange = (e) => {
        setCurrent({ ...current, [e.target.name]: e.target.value });
    }

    const handleOwnershipChange = (field, e) => {
        const newFormData = {
            ...current,
            [field]: e
        };

        let newUser = newFormData[field].filter(v => !current[field].includes(v));
        newUser = newUser.length > 0 ? newUser[0] : null;

        const otherField = field === 'owners' ? 'managers' : 'owners';

        if (newUser !== null) {
            newFormData[otherField] = newFormData[otherField].filter(v => v !== newUser);
        }

        setCurrent(newFormData);
    }

    const hasChanges = () => {
        if (initial === undefined || current === undefined) { return false; }
        if (initial.name !== current.name) { return true; }
        if (initial.key !== current.key) { return true; }
        if (initial.notes !== current.notes) { return true; }
        if (initial.owners.length !== current.owners.length) { return true; }
        if (initial.managers.length !== current.managers.length) { return true; }

        if (initial.owners.sort().toString() !== current.owners.sort().toString()) { return true; }
        if (initial.managers.sort().toString() !== current.managers.sort().toString()) { return true; }

        return false;

    }

    const getChanges = () => {

        const changes = [];

        if (initial.name !== current.name) { changes.push({ field: 'name', from: initial.name, to: current.name }); }
        if (initial.key !== current.key) { changes.push({ field: 'key', from: initial.key, to: current.key }); }
        if (initial.notes !== current.notes) { changes.push({ field: 'notes', from: initial.notes, to: current.notes }); }


        return changes;
    }

    return (
        <>
        <BackButton />
        {
        initial === undefined ? <Spinner size="large" />
        : <div className="flex flex-col gap-4">
            <p className="text-xl">Mailgun Key: {current.name}</p>
            <Input label="Name" name="name" value={current.name} onChange={handleChange}></Input>
            <Input label="Key" name="key" value={current.key} onChange={handleChange}></Input>
            <Textarea label="Notes" name="notes" value={current.notes} onChange={handleChange}></Textarea>
            <CheckboxGroup label="Owners" value={current.owners} onValueChange={e => { handleOwnershipChange('owners', e); }}>
            {
                usersData && usersData.map((user, idx) => {
                    return (
                        <Checkbox isDisabled={user._id === currentUser.data._id} key={idx} value={user._id}>{user.firstName} {user.lastname} ({user.username})</Checkbox>
                    );
                })
            }
            </CheckboxGroup>
            <CheckboxGroup label="Managers" value={current.managers} onValueChange={e => { handleOwnershipChange('managers', e); }}>
            {
                usersData && usersData.map((user, idx) => {
                    // Exclude the current user
                    if (user._id === currentUser.data._id) { return null; }
                    return (
                        <Checkbox key={idx} value={user._id}>{user.firstName} {user.lastname} ({user.username})</Checkbox>
                    );
                })
            }
            </CheckboxGroup>
            {
                !hasChanges() ? null
                : <div>
                    <h1 className="text-xl">Review your changes</h1>
                    <Table aria-label="Mailgun Key Edits">
                        <TableHeader>
                            <TableColumn>Field</TableColumn>
                            <TableColumn>From</TableColumn>
                            <TableColumn>To</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {
                                getChanges().map((change, idx) => {
                                    return (
                                        <TableRow key={`change-${idx}`}>
                                            <TableCell>{change.field}</TableCell>
                                            <TableCell>{change.from}</TableCell>
                                            <TableCell>{change.to}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                    <Button color="primary" onClick={() => {
                        const record = { ...current };
                        delete record['__v'];
                        const body = {
                            keyId: params.keyId,
                            record
                        }
                        putKey.mutate(body);
                        setTimeout(refetch, 1000);
                    }}>Save Changes</Button>
                </div>
            }
        </div>
        }
        </>
    );

}

export default MailgunKey;
