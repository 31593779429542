import {
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import LoggedInHeader from '../components/LoggedInHeader'

const queryClient = new QueryClient()

function Page() {
    
  return (
    <QueryClientProvider client={queryClient}>
        <LoggedInHeader />
        <section className="main-content">
            <div className="page">
                <Outlet />
            </div>
        </section>
    </QueryClientProvider>
  )
}

export default Page;
