
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button } from '@nextui-org/react'

import { LocationEditAction } from '../reducers/LocationEditsReducer';

const weekdays = [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ];

const LocationEditTable = (props) => {

    const { edits, dispatch, keySet, format } = props;


}

const LocationEditsSummary = (props) => {
    
    const { edits, dispatch } = props;


    const editKeys = [ 
        { key: 'basicInformation', display: 'Basic Information' },
        { key: 'hours', display: 'Hours' }
    ];

    const displays = {
        additionalPhone1: 'Additional Phone 1',
        additionalPhone2: 'Additional Phone 2',
        title: 'Business Name',
        description: 'Description',
        primaryPhone: 'Primary Phone',
        website: 'Website',
        address: 'Street Address',
        city: 'City',
        state: 'State',
        zip: 'Zip Code'
    }

    const hasEdits = () => {

        const keySets = [
            'basicInformation',
            'contact',
            'location',
            [ 'hours', 'regular' ],
            [ 'hours', 'special' ]
        ];

        if (edits === undefined) { return false; }

        for (let keySet of keySets) {
            if (!Array.isArray(keySet)) {
                if (Object.keys(edits[keySet]).length > 0) { return true; }
                continue;
            }

            // Get the object we want to deal with
            let obj = edits;
            for (let key of keySet) { obj = obj[key]; }

            if (Object.keys(obj).length > 0) { return true; }
        }

        return false;
    }

    const formatHours = (hours) => {
        if (hours.isClosed) {
            return (<em>Closed</em>);
        }

        return (
            <ul>
                { hours.periods.map(period => {
                    return (
                        <li>{period[0]} - {period[1]}</li>
                    )
                }) }
            </ul>
        );

    }

    const formatSpecialHours = (obj) => {
        if (obj === 'None') {
            return 'None';
        }

        if (obj.isClosed && obj.allDay) {
            return (<em>Closed</em>);
        }

        return `${obj.open} - ${obj.close}`

    }

    return (
        <div className="flex flex-col gap-4">
            { hasEdits() && <h3 className="text-xl">Review your edits to this location:</h3> }
            { 
                Object.keys(edits.basicInformation).length > 0 &&
                <>
                <p id="basicInformationEditsTable">Basic Information</p>
                <Table isStriped aria-labelledby="basicInformationEditsTable">
                    <TableHeader>
                        <TableColumn>FIELD</TableColumn>
                        <TableColumn>ORIGINAL</TableColumn>
                        <TableColumn>NEW</TableColumn>
                        <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            Object.keys(edits.basicInformation).map(key => {
                                return (
                                    <TableRow>
                                        <TableCell>{displays[key]}</TableCell>
                                        <TableCell>{edits.basicInformation[key].from}</TableCell>
                                        <TableCell>{edits.basicInformation[key].to}</TableCell>
                                        <TableCell><Button color="danger" aria-label="Remove edit" isIconOnly onPress={() => {
                                            dispatch({
                                                type: LocationEditAction.RevertEdit,
                                                category: 'basicInformation',
                                                key: key
                                            });
                                        }}>X</Button></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                </>
            }
            { 
                Object.keys(edits.contact).length > 0 &&
                <>
                <p id="contactEditsTable">Contact</p>
                <Table isStriped aria-labelledby="contactEditsTable">
                    <TableHeader>
                        <TableColumn>FIELD</TableColumn>
                        <TableColumn>ORIGINAL</TableColumn>
                        <TableColumn>NEW</TableColumn>
                        <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            Object.keys(edits.contact).map(key => {
                                return (
                                    <TableRow>
                                        <TableCell>{displays[key]}</TableCell>
                                        <TableCell>{edits.contact[key].from === '' ? (<em>None</em>) : edits.contact[key].from}</TableCell>
                                        <TableCell>{edits.contact[key].to}</TableCell>
                                        <TableCell><Button color="danger" aria-label="Remove edit" isIconOnly onPress={() => {
                                            dispatch({
                                                type: LocationEditAction.RevertEdit,
                                                category: 'contact',
                                                key: key
                                            });
                                        }}>X</Button></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                </>
            }
            {
                Object.keys(edits.location).length > 0 &&
                <>
                <p id="contactEditsTable">Location</p>
                <Table isStriped aria-labelledby="contactEditsTable">
                    <TableHeader>
                        <TableColumn>FIELD</TableColumn>
                        <TableColumn>ORIGINAL</TableColumn>
                        <TableColumn>NEW</TableColumn>
                        <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            Object.keys(edits.location).map(key => {
                                return (
                                    <TableRow>
                                        <TableCell>{displays[key]}</TableCell>
                                        <TableCell>{edits.location[key].from === '' ? (<em>None</em>) : edits.location[key].from}</TableCell>
                                        <TableCell>{edits.location[key].to}</TableCell>
                                        <TableCell><Button color="danger" aria-label="Remove edit" isIconOnly onPress={() => {
                                            dispatch({
                                                type: LocationEditAction.RevertEdit,
                                                category: 'location',
                                                key: key
                                            });
                                        }}>X</Button></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                </>
            }
            {
                Object.keys(edits.hours.regular).length > 0 && 
                <>
                <p id="regularHoursEditsTable">Regular Hours</p>
                <Table isStriped aria-labelledby='regularHoursEditsTable'>
                    <TableHeader>
                        <TableColumn>FIELD</TableColumn>
                        <TableColumn>ORIGINAL</TableColumn>
                        <TableColumn>NEW</TableColumn>
                        <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            weekdays.map(key => {
                                if (edits.hours.regular[key] === undefined) { return; }
                                return (
                                    <TableRow>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{formatHours(edits.hours.regular[key].from)}</TableCell>
                                        <TableCell>{formatHours(edits.hours.regular[key].to)}</TableCell>
                                        <TableCell><Button color="danger" aria-label="Remove edit" isIconOnly onPress={() => {
                                            dispatch({
                                                type: LocationEditAction.RevertEdit,
                                                category: 'hours',
                                                subcategory: 'regular',
                                                key: key
                                            });
                                        }}>X</Button></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                </>
            }
            {
                Object.keys(edits.hours.special).length > 0 && 
                <>
                <p id="regularHoursEditsTable">Special Hours</p>
                <Table isStriped aria-labelledby='regularHoursEditsTable'>
                    <TableHeader>
                        <TableColumn>DATE</TableColumn>
                        <TableColumn>ORIGINAL</TableColumn>
                        <TableColumn>NEW</TableColumn>
                        <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            Object.keys(edits.hours.special).map(key => {
                                if (edits.hours.special[key] === undefined) { return; }
                                return (
                                    <TableRow key={`regularHoursEditsTable-${key}`}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{formatSpecialHours(edits.hours.special[key].from)}</TableCell>
                                        <TableCell>{formatSpecialHours(edits.hours.special[key].to)}</TableCell>
                                        <TableCell><Button color="danger" aria-label="Remove edit" isIconOnly onPress={() => {
                                            dispatch({
                                                type: LocationEditAction.RevertEdit,
                                                category: 'hours',
                                                subcategory: 'special',
                                                key: key
                                            });
                                        }}>X</Button></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                </>
            }
            { hasEdits() && <div> <Button color="primary">Confirm and save edits</Button> </div> }
        </div>
    );

}

export default LocationEditsSummary;
