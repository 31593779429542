import ResetPasswordForm from '../components/ResetPasswordForm'


const ResetPassword = () => {
    return(
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <ResetPasswordForm />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword