import { createBrowserRouter } from "react-router-dom"
import {
    Accounts,
    Categories,
    Dashboard,
    ErrorPage,
    Home,
    Location,
    Locations,
    Login,
    MailgunCreateRoute,
    MailgunKey,
    MailgunRoute,
    MailgunHome,
    Page,
    Register,
    ResetPassword,
    ResetPasswordRequest,
    ReviewQuery
} from './Pages';

 const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
      children: [
        {
            path: "/",
            element: <Login/>
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/reset-password',
            element: <ResetPasswordRequest />
        },
        {
            path: '/reset-password/:token',
            element: <ResetPassword />
        },
        {
             path: '/register',
             element: <Register />
        }
      ]
    },
    {
        path: "/dashboard",
        element: <Page />,
        children: [
            {
                path: '/dashboard/home',
                element: <Dashboard />
            },
            {
                path: '/dashboard/accounts',
                element: <Accounts />,
            },
            {
                path: '/dashboard/accounts/:accountId/page/:page',
                element: <Locations />
            },
            {
                path: '/dashboard/accounts/googleauth',
                element: <Accounts />
            },
            {
                path: '/dashboard/accounts/:accountId/locations/:locationId',
                element: <Location />
            },
            {
                path: '/dashboard/categories',
                element: <Categories />
            },
            {
                path: '/dashboard/emails',
                element: <MailgunHome />
            },
            {
                path: '/dashboard/emails/create',
                element: <MailgunCreateRoute />
            },
            {
                path: '/dashboard/emails/:routeId',
                element: <MailgunRoute />
            },
            {
                path: '/dashboard/emails/key/:keyId',
                element: <MailgunKey />
            },
            {
                path: '/dashboard/review-query',
                element: <ReviewQuery />
            }
        ]
    }
])

export default router