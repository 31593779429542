//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import {useNavigate} from 'react-router-dom'

import { register } from '../endpoints/auth'

import { Input, Button } from '@nextui-org/react';

const registerObject = {
    email: '',
    password: '',
    firstName: '',
    lastName: ''
}

function RegisterForm() {

    const [formData, setFormData] = useState(registerObject)

    const navigate = useNavigate();

    const handleInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field]: value
        })
    }

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: register,
        onSuccess: () => {
          // Invalidate and refetch
          queryClient.invalidateQueries({ queryKey: ['register'] })
          navigate('/dashboard/home')
        },
    })
    
    const submitRegister = (e) => {
        e.preventDefault()
        mutation.mutate({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            username: formData.email,
            password: formData.password
        })
    }

    return (
        <>
        <form className="flex flex-col gap-4 p-4 bg-secondary rounded-lg" onSubmit={submitRegister}>
            <div className="flex flex-row gap-4">
                <Input id="register-first-name" label="First Name" name="firstName" value={formData.firstName} onValueChange={e => handleInputChange(e, 'firstName')}></Input>
                <Input id="register-last-name" label="Last Name" name="lastName" value={formData.lastName} onValueChange={e => handleInputChange(e, 'lastName')}></Input>
            </div>
            <div className="flex flex-col gap-4">
                <Input id="register-email" label="Email address" name="email" value={formData.email} onValueChange={e => handleInputChange(e, 'email')}></Input>
                <p className="text-white text-sm">We'll never share your email with anyone else.</p>
            </div>
            <Input id="register-password" type="password" label="Password" name="password" value={formData.password} onValueChange={e => handleInputChange(e, 'password')}></Input>
            <Button color="primary">Submit</Button>
        </form>
        {/*<Form onSubmit={submitRegister}>
            <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control name="firstName" type="text" placeholder="First Name" onChange={handleInputChange} value={formData.firstName} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control name="lastName" type="text" placeholder="Last Name" onChange={handleInputChange} value={formData.lastName} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control name="email" type="email" placeholder="Enter email" onChange={handleInputChange} value={formData.email} />
                <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control name="password" type="password" placeholder="Password" onChange={handleInputChange} value={formData.password} />
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>*/}
        </>
    );
}

export default RegisterForm;