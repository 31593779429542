//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
import {Link as RouterLink } from 'react-router-dom';
import {useState} from 'react'
import { Button, Input } from '@nextui-org/react';
import { Link } from '@nextui-org/react'

const loginObject = {
    email: '',
    password: ''
}

function LoginForm({handleOnSubmit}) {
    const [formData, setFormData] = useState(loginObject)

    const handleSubmit = e => {
        e.preventDefault()
        handleOnSubmit(formData)
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    return (
        <div className="container flex justify-center"> 
            <div className="login-form w-4/12">
                <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                    <Input name="email" label="Email" placeholder="We'll never share your email with anyone else" onChange={handleInputChange} value={formData.email}/>
                    <Input name="password" label="Password" type="password" onChange={handleInputChange} value={formData.password}/>
                    <Button color="primary" type="submit">Login</Button>
                    <div className="flex justify-between">
                        <Link className="text-white" href="/register">Register</Link>
                        <Link className="text-white" href="/reset-password">Reset Password</Link>
                    </div>
                </form>
                {/*<Form onSubmit={(e) => {
                    e.preventDefault()
                    handleOnSubmit(formData)
                }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name="email" type="email" placeholder="Enter email" onChange={handleInputChange} value={formData.email} />
                        <Form.Text className="field-description">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" type="password" placeholder="Password" onChange={handleInputChange} value={formData.password} />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <Form.Group className="mt-3">
                        <Link to="/reset-password">Reset Password</Link>
                    </Form.Group>
                </Form>*/}
            </div>
        </div>
    )
}

export default LoginForm;