import ApiKeysList from '../components/ApiKeysList';

function Dashboard() { 
    return (
        <section>
            <div className="container mx-auto">
                <div className="w-12/12">
                    <main>
                        <ApiKeysList />               
                    </main>
                </div>
            </div>
        </section>
    )
}

export default Dashboard;
