
import MailgunRouteComponent from '../../components/mailgun/MailgunRoute'

function MailgunRoute() {    
    return (
        <section>
            <div className="container flex mx-auto">
                <div className="w-full px-4">
                    <main>
                        <MailgunRouteComponent />
                    </main>
                </div>
            </div>
        </section>
    )
}

export default MailgunRoute;
