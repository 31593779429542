//import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import LoginForm from '../components/LoginForm'
import {googleAuth} from '../endpoints/auth'

function GoogleLogin() {

    const queryClient = useQueryClient()

    const navigate = useNavigate();

    const {isLoading, isError, data, error, refetch} = useQuery({ queryKey: ['google'], queryFn: googleAuth, enabled: false, manual: true })

    const login = `${process.env.REACT_APP_ENDPOINT_URL}/google/login`

    return (
        <div className="GoogleLogin">
            <h3 className="text-xl"><i className="fas fa-book-reader"></i> Google Business API</h3>
            <div className="section">
                <p className="lead">
                    Login to connect your google account
                </p>
            </div>
            <div className="section">
                <a href={login}>
                    <i className="fab fa-google left"></i> Login with Google
                </a>
            </div>
        </div>
    );
}

export default GoogleLogin;