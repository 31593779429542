import React from 'react';
import ReactDOM from 'react-dom/client';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/css/app.css';
import './assets/css/loginForm.css'
import './assets/css/buttons.css'
import './assets/css/keys.css'
import './assets/css/navbar.css'

import reportWebVitals from './reportWebVitals';
import App from './App'
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import router from './router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}>
            <main className="dark text-foreground bg-background">
                <App />
            </main>
        </RouterProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
