import RegisterForm from '../components/RegisterForm'

const LoginPage = () => {
    return(
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <RegisterForm />
                </div>
            </div>
        </div>
    )
}

export default LoginPage