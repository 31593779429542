import { listReviews, syncReviews } from '../endpoints/googlebusinessprofile.reviews';
import { getLocation } from '../endpoints/googlebusinessprofile.locations'
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useState, useReducer } from 'react';
import { Button, Accordion, AccordionItem, Select, SelectItem, Input, Textarea } from '@nextui-org/react'
import { Tabs, Tab } from '@nextui-org/react'

import LocationEditsSummary from './LocationEditsSummary';
import LocationHoursTable from './LocationHoursTable';
import LocationSpecialHoursTable from './LocationSpecialHoursTable';
import ReviewsCommonWordsChart from '../charts/ReviewsCommonWordsChart';
import ReviewsRatingBreakdownChart from '../charts/ReviewsRatingBreakdownChart';

import { LocationEditsReducer, LocationEditAction } from '../reducers/LocationEditsReducer';


const weekdays = [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ];

const GoogleBusinessProfileReviews = () => {

    const [ edit, dispatch ] = useReducer(LocationEditsReducer, { })

    const [ editableData, setEditableData ] = useState(undefined);
    const [ edits, setEdits ] = useState({ basicInformation: { }, hours: { } });

    const { accountId, locationId } = useParams();
    const { data, refetch } = useQuery({
        queryKey: [ 'googleBusinessReviews', accountId, locationId ],
        queryFn: listReviews
    });

    const { data: syncData, fetchStatus: syncFetchStatus, refetch: syncRefetch } = useQuery({
        queryKey: [ 'googleBusinessReviews', accountId, locationId  ], 
        queryFn: syncReviews,
        placeholderData: undefined,
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const locationData = useQuery({
        queryKey: ['googleBusinessLocation', locationId], 
        queryFn: getLocation,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            // Make a few modifications to the data before we set it in an editable state.
            // For any closed days, create objects anyways so they exist
            const editable = JSON.parse(JSON.stringify(data));

            weekdays.forEach(day => {
                if (editable.regularHours.periods.find(v => v.openDay === day) === undefined) {
                    editable.regularHours.periods.push({
                        openDay: day,
                        markedAsClosed: true,
                        openTime: { hours: '', minutes: '' },
                        closeTime: { hours: '', minutes: '' }
                    });
                }
            });

            dispatch({
                type: LocationEditAction.Setup,
                data: data
            });

            setEditableData(editable);
        }
    })

    const reviews = data?.reviews;
    const count = locationData?.data?.reviewAggregate?.totalReviewCount || 0;
    const averageRating = locationData?.data?.reviewAggregate?.averageRating.toFixed(2) || 0;

    const sortOptions = {
        'Date: Most recent to oldest': (a, b) => new Date(b.updateTime) - new Date(a.updateTime),
        'Date: Oldest to most recent': (a, b) => new Date(a.updateTime) - new Date(b.updateTime),
        'Rating: Highest to lowest': (a, b) => b.starRating - a.starRating,
        'Rating: Lowest to highest': (a, b) => a.starRating - b.starRating
    }
    const [ sortFxn, setSortFxn ] = useState(Object.keys(sortOptions)[0]);

    const handleSortChange = (e) => {
        setSortFxn(e.target.value);
    }



    const onSync = () => {
        syncRefetch().then(res => {
            // We want to ensure that we pull from mongo directly after a sync.
            refetch()
            locationData.refetch()
        });
    };

    function getFormattedDate(date) {
        var year = date.getFullYear();
      
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    const editsExist = () => {
        return Object.keys(edit.edits).reduce((total, cur) => {
            return total || (Object.keys(edit.edits[cur]).length > 0);
        }, false);
    }

    // Generate a list of US states (full state name) for the state select field
    const stateSelectOptions = () => {
        const stateNames = [
            'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
            'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
            'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
            'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 
            'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
            'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 
            'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
          ];
        let states = [];
        for (let state of stateNames) {
            states.push(<SelectItem key={state} value={state}>{state}</SelectItem>);
        }
        return states;
    }


    return (
        <>
        <div className="button-row">
            { data && <Button color="primary" onClick={onSync} isLoading={syncFetchStatus === 'fetching'}>Sync Reviews</Button> }
            { data && syncFetchStatus === 'fetching' && <span>Syncing...</span> }
        </div>
        { data && editableData && (
            <Tabs>
                <Tab title="Location Information">
                    <div className="flex flex-col gap-2 py-4">
                        <Accordion isCompact selectionMode='multiple' variant="bordered">
                            <AccordionItem title="Basic Information">
                                <div className="flex flex-col gap-4 py-4">
                                    <Input label="Title" value={edit.editable.basicInformation.title} onValueChange={e => {
                                        dispatch({ type: LocationEditAction.BasicInformation, field: 'title', value: e })
                                    }}></Input>
                                    <Textarea label="Description" value={edit.editable.basicInformation.description} onValueChange={e => {
                                        dispatch({ type: LocationEditAction.BasicInformation, field: 'description', value: e })
                                     }}></Textarea>
                                </div>
                            </AccordionItem>
                            <AccordionItem title="Contact">
                                <div className="flex flex-col gap-4 py-4">
                                    <Input label="Primary Phone" value={edit.editable.contact.primaryPhone} onValueChange={e => {
                                        dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'contact', field: 'primaryPhone', value: e })
                                    }}></Input>
                                    <div className="flex flex-row gap-2">
                                        <Input label="Additional Phone 1" value={edit.editable.contact.additionalPhone1} onValueChange={e => {
                                            dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'contact', field: 'additionalPhone1', value: e })
                                        }}></Input>
                                        <Input label="Additional Phone 2" value={edit.editable.contact.additionalPhone2} onValueChange={e => {
                                            dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'contact', field: 'additionalPhone2', value: e })
                                        }}></Input>
                                    </div>
                                    <Input label="Website" value={edit.editable.contact.website} onValueChange={e => {
                                        dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'contact', field: 'website', value: e })
                                    }}></Input>
                                </div>
                            </AccordionItem>
                            <AccordionItem title="Location">
                                <div className="flex flex-col gap-4 py-4">
                                    <Input label="Street Address" value={edit.editable.location.address} onValueChange={e => {
                                        dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'location', field: 'address', value: e })
                                    }}></Input>
                                    <Input label="City" value={edit.editable.location.city} onValueChange={e => {
                                        dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'location', field: 'city', value: e })
                                    }}></Input>
                                    <div className="flex gap-2">
                                        <Select label="State" selectedKeys={[ edit.editable.location.state ]} onSelectionChange={e => {
                                            dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'location', field: 'state', value: Object.values(e)[0] })
                                        }}>{stateSelectOptions()}</Select>
                                        <Input label="Zip Code" value={edit.editable.location.zip} onValueChange={e => {
                                            dispatch({ type: LocationEditAction.BasicFieldEdit, category: 'location', field: 'zip', value: e })
                                        }}></Input>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem title="Regular Hours">
                                <LocationHoursTable { ...{ edit, dispatch, weekdays } } />
                            </AccordionItem>
                            <AccordionItem title="Special Hours">
                                <LocationSpecialHoursTable { ...{ edit, dispatch } } />
                            </AccordionItem>
                            <AccordionItem title="Categories">
                                <p>TODO</p>
                            </AccordionItem>
                            <AccordionItem title="Service Items">
                                <p>TODO</p>
                            </AccordionItem>
                        </Accordion>
                        { edit /*&& editsExist()*/ && (
                            <>
                            <LocationEditsSummary edits={edit.edits} dispatch={dispatch}/>
                            </>
                        ) }
                    </div>
                </Tab>
                <Tab title="Reviews">
                    <div className="flex flex-col gap-2 py-4">
                        <p>Total Count: {count}</p>
                        {count > 0 &&
                        <>
                        <p>Average Rating: {averageRating}</p>
                        <Select label="Sort By" selectedKeys={[ sortFxn ]} onChange={handleSortChange}>
                            {
                                Object.keys(sortOptions).map((key, idx) =>  <SelectItem key={key} value={key} >{key}</SelectItem>)
                            }
                        </Select>
                        </>}
                    </div>
                    {count > 0 && <Accordion isCompact selectionMode='multiple' variant="bordered">
                        { reviews && reviews.sort(sortOptions[sortFxn]).map((review, idx) => {
                            return (
                                <AccordionItem key={idx} title={`${review.reviewer.displayName}`} subtitle={`${new Array(review.starRating).fill('⭐').join('')} - ${review.updateTime}`}>
                                    <p>{review.comment ?? (<em>The user did not provide a comment with their review.</em>)}</p>
                                </AccordionItem>
                            );
                        }) }
                    </Accordion> } 
                </Tab>
                <Tab title="Data">
                    <Accordion isCompact variant="bordered">
                        <AccordionItem title="Rating Breakdown">
                            <ReviewsRatingBreakdownChart { ...{ accountId, locationId } } />
                        </AccordionItem>
                        <AccordionItem title="Review Comments Common Words">
                            <ReviewsCommonWordsChart { ...{ accountId, locationId } } />
                        </AccordionItem>
                    </Accordion>


                </Tab>
            </Tabs>
        )}
        </>
    );
};

export default GoogleBusinessProfileReviews;