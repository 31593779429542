import axios from 'axios'

const createKey = async (keyName) => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            keyName : keyName.trim()
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/keys/create`, config);
    return response.data;

}

const getKey = async ({queryKey}) => {
    const [ _] = queryKey;

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/keys/get`, config);
    return response.data;

}

const getKeys = async ({queryKey}) => {
    const [ _ ] = queryKey;

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/keys/list`, config);
    return response.data;
}

const revokeKey = async (keyID) => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            keyToRevoke : keyID
        }
    }
    const response = await axios.delete(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/keys/revoke`, config);
    return response.data;
}

export { createKey, getKey, getKeys, revokeKey }
