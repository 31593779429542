

import { useQuery } from '@tanstack/react-query';
import { getUserRoutes, getUserKeys } from '../../endpoints/mailgun';
import { Table, TableHeader, TableColumn, TableBody, TableCell, TableRow, Spinner, Button, Link, Input, Pagination, Select, SelectItem } from '@nextui-org/react';
import { useState, useMemo } from 'react';
import { getUser } from '../../endpoints/auth';
import RouterLink from '../RouterLink';
import { useNavigate } from 'react-router-dom';

const parseActions = (actions) => {
    return actions.filter(v => v != 'stop()').map(v => v.split('"')[1].split(',').join(', ')).join(', ')
}

const MailgunRoutesList = (props) => {

    const navigate = useNavigate();

    const { user: currentUser } = props;

    const [ filter, setFilter ] = useState('');

    const [ page, setPage ] = useState(1);
    const pageSizes = [ 10, 25, 50, 100, 'All' ];
    const [ rowsPerPage, setRowsPerPage ] = useState('10');
    const [ pageCount, setPageCount ] = useState(1);
    const [ account, setAccount ] = useState('all');

    // Mailgun routes
    const { data, status, fetchStatus, refetch } = useQuery({
        queryKey: [ 'mailgunRoutes', currentUser ],
        queryFn: getUserRoutes,
        placeholderData: undefined,
        onSuccess : data => {
            setPageCount(Math.ceil(data.length / rowsPerPage));
        }
    });

    const { data: keys } = useQuery({
        queryKey: [ 'mailgunKeys', currentUser ],
        queryFn: getUserKeys,
        placeholderData: undefined
    });

    const items = useMemo(() => {
        if (data == undefined || data.length == 0) { return []; }

        const recordCount = rowsPerPage === 'All' ? data.length : parseInt(rowsPerPage);

        const start = (page - 1) * recordCount
        const end = start + recordCount;
        let displayData = data;

        if (account !== 'all') {
            displayData = displayData.filter(route => route.key === account);
        }

        if (filter !== '') {
            displayData = displayData.filter(route => {
                return route.expression.split('"')[1].indexOf(filter) > -1 || route.actions.toString().indexOf(filter) > -1;
            });
        }

        return displayData.sort((a, b) => a.expression.split('"')[1] > b.expression.split('"')[1] ? 1 : -1).slice(start, end);
    }, [ page, data, rowsPerPage, account, filter ]);

    return (
        <>
        {data ? 
        <div className="flex flex-col gap-4">
            <div className="flex w-full justify-end">
                <Button onClick={() => {
                    navigate('/dashboard/emails/create');
                }}>Create Route</Button>
            </div>
            <div className="flex pb-5 gap-4">
                <Input label="Search" value={filter} onValueChange={setFilter}></Input>
                <Select label="Account" selectedKeys={[ account ]} onChange={e => {
                    setAccount(e.target.value);
                }}>
                    <SelectItem key='all' value="all">All</SelectItem>
                    {
                        keys && keys.map((key, index) => {

                            return (
                                <SelectItem key={`${key.key}`} value={key.key}>{key.name}</SelectItem>
                            );
                        })
                    }
                </Select>
                <Select label="Rows per page" selectedKeys={[ rowsPerPage ]} onChange={e => {
                    console.log(e.target.value);
                    if (e.target.value === 'All') {
                        console.log('All')
                        setRowsPerPage('All');
                        setPageCount(1);
                        return;
                    }
                    setRowsPerPage(e.target.value);
                    setPageCount(Math.ceil(data.length / e.target.value));
                }}>
                {
                    pageSizes.map((size, index) => {
                        return (
                            <SelectItem key={size} textValue={`${size}`} value={size}>{size}</SelectItem>
                        );
                    })
                }
                </Select>
                <Button className="" onClick={refetch}>Refresh</Button>
            </div>
            { data !== undefined && items.length > 0 ? <Table isStriped
                aria-label="Mailgun Routes"
                bottomContent={
                    <div className="flex w-full justify-center">
                        <Pagination
                            isCompact
                            showControls
                            page={page}
                            total={pageCount}
                            onChange={page => setPage(page)}
                        />
                    </div>
                }>
                <TableHeader>
                    <TableColumn>NAME</TableColumn>
                    <TableColumn>FORWARDS</TableColumn>
                    <TableColumn>ACTIONS</TableColumn>
                </TableHeader>
                <TableBody>
                    {items.map((route, idx) => {
                        return (
                            <TableRow key={`route-${idx}`}>
                                <TableCell>{route.expression.split('"')[1]}</TableCell>
                                <TableCell>{parseActions(route.actions)}</TableCell>
                                <TableCell>
                                    <RouterLink to={`/dashboard/emails/${route.id}`} state={{
                                        key: route.key
                                    }}>Edit</RouterLink>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table> : <p> No results found for given filters. </p>} 
        </div> : <Spinner />}
    </>
    );

}

export default MailgunRoutesList;
