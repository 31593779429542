
import { SelectItem } from '@nextui-org/react';

// Corresponding # of days in each month
const monthDayCounts = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31 ,30, 31 ];

// Creates SelectItem options in half hour increments from 12:00 AM to 11:30 PM. Provides a "24 hours" option
const selectTimeOptions = () => {
    let timeOptions = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
            let time = `${i > 12 ? i - 12 : (i === 0) ? 12 : i}:${j === 0 ? '00' : '30'} ${i < 12 ? 'AM' : 'PM'}`;
            timeOptions.push(time);
        }
    }
    timeOptions.push('24 hours');
    return timeOptions.map(v => <SelectItem key={v} value={v}>{v}</SelectItem>);
}

// The days of the week
const weekdays = [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ];

export { monthDayCounts, selectTimeOptions, weekdays };
