import {listAccounts, syncGroups, syncAll, groupDetails} from '../endpoints/googlebusinessprofile.accounts'
import {useQuery} from '@tanstack/react-query'
//import {Link as RouterLink} from 'react-router-dom'

import { Button, Link, Spinner } from '@nextui-org/react'
import { Table, TableBody, TableRow, TableHeader, TableColumn, TableCell } from '@nextui-org/react'
import RouterLink from './RouterLink'

const GoogleBusinessProfileAccounts = () => {

    const { data, refetch } = useQuery({
        queryKey: [ 'googleBusinessAccounts' ],
        queryFn: listAccounts,
        placeholderData: undefined
    });

    const { data: syncData, fetchStatus: syncFetchStatus, refetch: syncRefetch} = useQuery({
        queryKey: [ 'googleBusinessAccountsSyncAll' ],
        queryFn: syncAll,
        placeholderData: undefined,
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });
    
    const { data: syncGroupsData, fetchStatus: syncGroupsFetchStatus, refetch: syncGroupsRefetch} = useQuery({
        queryKey: [ 'googleBusinessAccountsSyncGroups' ],
        queryFn: syncGroups,
        placeholderData: undefined,
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const { data: groupDetailsData, fetchStatus: groupDetailsFetchStatus, refetch: groupDetailsRefetch} = useQuery({
        queryKey: [ 'googleBusinessAccountsGroupDetails' ],
        queryFn: groupDetails,
        placeholderData: undefined
    });

    const onSync = () => {
        syncRefetch().then(res => {
            // We want to ensure that we pull from mongo directly after a sync.
            refetch()
        });
    }

    const onSyncGroups = () => {
        syncGroupsRefetch().then(res => {
            // We want to ensure that we pull from mongo directly after a sync.
            refetch()
        });
        groupDetailsRefetch();
    }


    return(
        <>
        {data && (
            <div className="button-row">
                <Button color="primary" onClick={onSyncGroups} isLoading={syncGroupsFetchStatus === 'fetching'}>Sync Google Business Profile Accounts (Groups)</Button>
                <Button color="primary" onClick={onSync} isLoading={syncFetchStatus === 'fetching'}>Sync All Locations and Reviews</Button>
                { syncFetchStatus === 'fetching' && <span>Syncing... This might take a while...</span> }
                { syncGroupsFetchStatus === 'fetching' && <span>Syncing Groups...</span> }
            </div>
        )}

        <Table isStriped>
            <TableHeader>
                <TableColumn>NAME</TableColumn>
                <TableColumn># OF LOCATIONS</TableColumn>
                <TableColumn># OF REVIEWS </TableColumn>
            </TableHeader>
            <TableBody emptyContent={<Spinner/>}>
                {data &&
                    data.map(account => {
                        return(
                            account.accounts.map(a => {
                                const state = { accessToken: account.accessToken, groupId: a.name.split('/')[1] };
                                return (
                                    <TableRow key={a._id}>
                                        {/*<TableCell><RouterLink to={`/dashboard/${a.name}/page/1`} state={state}>{a.accountName}</RouterLink></TableCell>*/}
                                        <TableCell><RouterLink to={`/dashboard/${a.name}/page/1`} state={state}>{a.accountName}</RouterLink></TableCell>
                                        <TableCell>{groupDetailsData !== undefined ? groupDetailsData[a.name].locations : ''}</TableCell>
                                        <TableCell>{groupDetailsData !== undefined ? groupDetailsData[a.name].reviews : ''}</TableCell>
                                    </TableRow>
                                )
                            })
                        )
                    })
                }
            </TableBody>
        </Table>
        </>
    )
}

export default GoogleBusinessProfileAccounts