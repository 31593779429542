//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import {useNavigate} from 'react-router-dom'

import { passwordResetRequest } from '../endpoints/auth'

import { Input, Button } from '@nextui-org/react';

function ResetPasswordRequestForm() {

    const [resetSent, setResetSent] = useState(undefined);

    const [resetEmail, setResetEmail] = useState("");

    
    const navigate = useNavigate();

    const handleInputChange = value => {
        setResetEmail(value);
    }

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: passwordResetRequest,
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: [ 'register' ] })
            queryClient.invalidateQueries({ queryKey: [ 'passwordResetRequest' ] })
            //navigate('/');
            setResetSent({
                sentTo: resetEmail
            });
            setResetEmail("");
        },
    })
    
    // const submitRegister = (e) => {
    //     e.preventDefault()
    //     mutation.mutate({
    //         firstName: formData.firstName,
    //         lastName: formData.lastName,
    //         email: formData.email,
    //         username: formData.email,
    //         password: formData.password
    //     })
    // }

    const submitReset = e => {
        e.preventDefault();
        mutation.mutate({
            email: resetEmail
        });
    }

    return (
        <>
        <div className="container flex justify-center"> 
            <div className="request-reset-password-form">
                <form className="flex flex-col gap-4 p-4 bg-secondary rounded-lg" onSubmit={submitReset}>
                    {
                        resetSent !== undefined ? <p className="text-white">We sent a reset email to {resetSent.sentTo}, check your inbox!</p>
                        : <>
                            <div className="flex flex-row gap-4">
                                <p className="text-white">We'll send you an email to reset your password.</p>
                            </div>
                            <div className="flex flex-col gap-4">
                                <Input id="email" label="Email address" name="email" value={resetEmail} onValueChange={e => handleInputChange(e)}></Input>
                            </div>
                            <Button type="submit" color="primary">Submit</Button>
                        </>
                    }
{/*                    
                    {
                        resetSent !== undefined ? <p className="text-white">We sent a reset email to {resetSent.sentTo}, check your inbox!</p> : null
                    } */}
                </form>
            </div>
        </div>
        </>
    );
}

export default ResetPasswordRequestForm;