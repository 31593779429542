
import MailgunAddNewRoute from '../../components/mailgun/MailgunAddNewRoute'

function CreateMailgunRoute() {    
    return (
        <section>
            <div className="container flex mx-auto">
                <div className="w-full px-4">
                    <main>
                        <MailgunAddNewRoute />
                    </main>
                </div>
            </div>
        </section>
    )
}

export default CreateMailgunRoute;
