import GoogleLogin from '../components/GoogleLogin'
import AccountsList from '../components/AccountsList'
import GoogleBusinessProfileLocations from '../components/GoogleBusinessProfileLocations'

function Locations() {    

    return (
        <section>
            <div className="container flex mx-auto">
                <div className="w-3/12 px-4">
                    <aside className="sidebar">
                        <h2>Authenticate Accounts</h2>
                        <GoogleLogin />
                        <hr />
                        <h3>Added Accounts</h3>
                        <AccountsList />
                    </aside>
                </div>
                <div className="w-9/12 px-4">
                    <main>
                        <h1>Your GMB Locations List</h1>
                        <GoogleBusinessProfileLocations />
                    </main>
                </div>
            </div>
        </section>
    )
}

export default Locations
