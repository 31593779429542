
import { useQuery } from '@tanstack/react-query';
import { listCategories, syncCategories } from '../endpoints/categories';

import { useState } from 'react';

import { Table, TableHeader, TableColumn, TableBody, TableCell, TableRow, Spinner, Button, Pagination } from '@nextui-org/react';

const CategoriesList = () => {

    const { data, fetchStatus, refetch } = useQuery({
        queryKey: [ 'categories' ],
        queryFn: listCategories,
        placeholderData: [ ]
    });

    const { data: syncData, fetchStatus: syncFetchStatus, refetch: syncRefetch } = useQuery({
        queryKey: [ 'categoriesSync' ], 
        queryFn: syncCategories,
        placeholderData: undefined,
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const onSync = () => {
        syncRefetch().then(res => {
            // We want to ensure that we pull from mongo directly after a sync.
            refetch()
            data.refetch()
        });
    };

    return (data &&
        <>
        <Button color="primary" isDisabled={fetchStatus === 'fetching'} onPress={onSync}>Sync Categories</Button>
        <Table emptyContent={<Spinner/>}>
            <TableHeader>
                <TableColumn>NAME</TableColumn>
                <TableColumn>DISPLAY NAME</TableColumn>
            </TableHeader>
            <TableBody>
                { data.map((category, idx) => {
                    return (
                        <TableRow key={idx}>
                            <TableCell>{category.name}</TableCell>
                            <TableCell>{category.displayName}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
        </>
    );

}

export default CategoriesList;
