import axios from 'axios'

const listCategories = async () => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/categories/list/`, config)
    return response.data.data;
}

const syncCategories = async () => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/categories/sync/`, config)
    return response.data.data;
}

export { listCategories, syncCategories }
