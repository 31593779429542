import axios from 'axios'

const login = async (req) => {
    const config = {
        withCredentials: true,
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json"
        }
    }
    const body = {
            username: req.email,
            password: req.password,
            
    }
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/auth/login`, body, config)
    return response
}

const logout = async (req) => {
    const config = {
        withCredentials: true,
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/auth/logout`, {}, config)
    return response
}

const getUser = async () => {
    const config = {
        withCredentials: true
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/auth/user`, config)
    return response
}

const authenticated = async (req) => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
        return true
    }
    return false
}


const register = async (req) => {
    const body = {
        firstName: req.firstName,
        lastName: req.lastName,
        email: req.email,
        username: req.email,
        password: req.password
    }
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/auth/register`, body)
    return response
}

const passwordResetRequest = async req => {
    const body = {
        email: req.email
    };
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/auth/password-reset-request`, body);
    return 'password reset request';
}

const passwordReset = async req => {
    const body = {
        password: req.password,
        token: req.token
    }

    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/auth/password-reset`, body)
    //const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/auth/reset`, body)
    // const response = await axios.post(`${process.env.REASCT_APP_ENDPOINT_URL}/auth/resetPassword`, body)
    return 'password reset';
}

const googleAuth = async (req) => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/google/login`, config)
    return response
}

export { login, logout, getUser, authenticated, register, googleAuth, passwordResetRequest, passwordReset }