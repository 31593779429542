import { useNavigate } from 'react-router-dom'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import LoginForm from '../components/LoginForm'
import {login} from '../endpoints/auth'


const LoginPage = () => {

    const queryClient = useQueryClient()

    const navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: login,
        onSuccess: async ({data}) => {
          // Invalidate and refetch
          queryClient.invalidateQueries({ queryKey: ['login'] })
          localStorage.setItem('user', data.id)
          navigate('/dashboard/home');
        },
    })
    
    const submitLogin = async (formData) => {
        mutation.mutate({
            email: formData.email,
            password: formData.password
        })
    }
    return(
        <LoginForm handleOnSubmit={submitLogin} />
    )
}

export default LoginPage