

import { useQuery } from '@tanstack/react-query';
import { useRef, useMemo } from 'react';
import { Spinner } from '@nextui-org/react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import { listReviews } from '../endpoints/googlebusinessprofile.reviews';

const chartOptions = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right'
        },
        title: {
            display: true,
            text: 'Reviews: Rating Breakdown',
        }
    }
};

const formatChartData = data => {

    if (data === undefined) {
        return { };
    }

    const groupedByRating = data.reviews.reduce((total, cur) => {
        if (total[cur.starRating] === undefined) {
            total[cur.starRating] = 1;
        } else {
            total[cur.starRating] += 1;
        }
        return total;
    }, { });


    // Make sure that even if a type of rating wasn't present, it still gets included in the chart
    Array(5).fill(0).map((v, idx) => idx + 1).forEach(v => {
        if (groupedByRating[v] === undefined) {
            groupedByRating[v] = 0;
        }
    });

    const chartData = Object.keys(groupedByRating).sort((a, b) => b - a).map(v => groupedByRating[v]);

    return {
        labels: new Array(5).fill(0).map((v, idx, arr) => arr.length - idx),
        datasets: [
            {
                label: "# of ratings",
                data: chartData,
                backgroundColor: '#3aad87',
                borderColor: '#2e8a6b',
            }
        ]
    }

}

const ReviewsRatingBreakdownChart = props => {

    const { accountId, locationId } = props;

    const ref = useRef();

    const { data, refetch } = useQuery([ 'googleBusinessReviews', accountId, locationId ], listReviews);

    const chartData = useMemo(() => formatChartData(data), [ data ]);

    return (
        <>
        { data && chartData ?
            <div className={`h-[${chartData.datasets[0].data.length*16}px]`} style={{ height: `${24*7}px`, minHeight:`${24*7}px` }}>
                <div className={`chart-container flex relative w-100% h-full`}> 
                    <Bar responsive ref={ref} key="reviewRatingBreakdownChart" data={chartData} options={chartOptions}/>
                </div>
            </div> :
            <Spinner/>
        }
        </>
    );

}

export default ReviewsRatingBreakdownChart;
